export const photosetLifeCycleStructRetouche = [
    {
        name: "Review",
        title:"Pick Retouche Photos",
        subtitle:"Pick up the photos for retouche",
        status: "current"
    },
    {
        name: "Reviewed",
        title:"Retouche",
        subtitle:"Here the selected photos are retouched",
        status: "future"
    },
    {
        name: "Ready",
        title:"Photos Ready",
        subtitle:"Select and download",
        status: "future"
    },
    {
        name: "Downloaded",
        title:"Downloaded",
        subtitle:"You're done!",
        status: "future"
    }
]

export const photosetLifeCycleStructDownload = [
    {
        name: "Ready",
        title:"Photos Ready",
        subtitle:"Select and download",
        status: "current"
    },
    {
        name: "Downloaded",
        title:"Downloaded",
        subtitle:"You're done!",
        status: "future"
    }
]