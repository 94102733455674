<template>
    <span :class="tagStatus" class="app-tag is-unselectable">
        <span :class="fontSizeClass" class="material-icons-outlined mr-1" v-html="icon"></span>
        <div :class="fontSizeClass" class="is-inline">{{ text }}</div>
    </span>
</template>

<script>

export default {
    name: "AppTag",
    props: ['icon', 'text', 'fontSizeClass', 'tagStatus']
}
</script>

<style scoped>

.app-tag {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    line-height: 1.5;
    padding-left: 0.75em;
    padding-right: 0.75em;
    white-space: nowrap;
    border-radius: 12px;
    box-shadow: inset 0 0 0 1px;
  }

</style>