<template>
  <div class="photo-card-size-m-cart" >
    <span class="badge is-success" v-if="photo.downloaded" >
        <span class="material-icons-outlined corner-badge is-unselectable" >
          arrow_downward
        </span>
    </span>
    <figure v-lazyload class="photo-card-size-m-image">
        <img :data-src="photo.src.l['0']" :alt="photo.name"  class="lazy" @load="imageLoaded = true" v-show="imageLoaded">
        <Skeletor height="300" class="is-border-radius-9 mb-2" v-if="!imageLoaded"/>
            <span v-show="imageLoaded" class="material-icons-outlined bottom-right-icon has-background-white has-text-primary is-round-icon is-clickable is-unselectable interactive-icon photo-download-icon" @click="downloadItem()" >
                    file_download
            </span>
    </figure>   
    <div class="photo-card-size-m-filename has-text-centered has-text-weight-bold grey-500 is-size-7 is-text-overflow-ellipsis" >
        {{ photo.name }}
    </div>
  </div>
</template>

<script>
import { Skeletor } from "vue-skeletor";
export default {
    name: "PhotoCard",
    props: ['photo'],
    methods: {
        downloadItem() {
            this.$store.dispatch('downloadItem', this.photo.id)
        }
    },
    components: {
        Skeletor
    },
    data() {
        return {
            imageLoaded: false
        }
    }
}
</script>

<style scoped>

.bottom-right-icon {
  background-color: white;
  position: absolute;
  bottom: 35px;
  right: 5px;
  font-size: 18px;
}

.material-icons-outlined.photo-download-icon {
    font-size: 18px;
  position: absolute;

}


@media screen and (max-width: 768px) {
    .material-icons-outlined.photo-download-icon {
        font-size: 28px;
    }
}


</style>