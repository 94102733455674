<template>

  <nav class="level">

    <div class="level-left">
      <div class="level-item pr-2" v-if="customerPhotoset.isFavoritesEnabled">
        <p class="is-clickable status-tag has-text-weight-bold tag is-outlined is-unselectable is-rounded is-relative pl-2 is-size-6">
            <span
                class="material-icons-outlined is-size-7 has-text-weight-bold icons has-text-danger is-unselectable pr-1"> favorite </span>
          Favorites
        </p>
      </div>
      <div class="level-item" v-if="isRetouchebale">

        <div class="pr-4" @click="handleSorting">
          <p :class="[{'is-light is-tag-selected': !photoSelectedTags.retouched}, 'is-clickable status-tag has-text-weight-bold tag is-success is-unselectable is-rounded is-relative pl-2 is-size-6']"
             id="sort-retouched">
          <span title="Badge top left" :class="[{'is-light': !photoSelectedTags.retouched}, 'badge is-top-right']">
            {{ retouched }}
          </span>
            Retouched
          </p>
        </div>
        <div @click="handleSorting">
          <p :class="[{'is-light is-tag-selected': !photoSelectedTags.unretouched}, 'is-clickable status-tag has-text-weight-bold tag is-success is-unselectable is-rounded is-relative pl-2 is-size-6']"
             id="sort-unretouched">
          <span title="Badge top left"
                :class="[{'is-light': !photoSelectedTags.unretouched}, 'badge is-top-right']">{{ unretouched }}</span>
            Unretouched
          </p>
        </div>
      </div>
    </div>


    <div class="level-right">
      <div class="level-item pl-2" @click="handleSorting">
        <p :class="[{'is-light is-tag-selected': !photoSelectedTags.all}, 'is-clickable status-tag has-text-weight-bold tag is-success is-unselectable is-rounded is-relative pl-2 is-size-6']"
           id="sort-all">
          <span title="Badge top left" :class="[{'is-light': !photoSelectedTags.all}, 'badge is-top-right']">
            {{ customerPhotoset.photoCount }}
          </span>
          All
        </p>
      </div>
      <div class="level-item pl-2" v-if="downloaded > 0" @click="handleSorting">
        <p :class="[{'is-light is-tag-selected': !photoSelectedTags.downloaded}, 'is-clickable status-tag has-text-weight-bold tag is-success is-unselectable is-rounded is-relative pl-2 is-size-6']"
           id="sort-downloaded">
          <span title="Badge top left"
                :class="[{'is-light': !photoSelectedTags.downloaded}, 'badge is-top-right']">{{ downloaded }}</span>
          Downloaded
        </p>
      </div>
      <div class="level-item pl-2" @click="handleSorting">
        <p :class="[{'is-light is-tag-selected': !photoSelectedTags.selected}, 'is-clickable status-tag has-text-weight-bold tag is-success is-unselectable is-rounded is-relative pl-2 is-size-6']"
           id="sort-selected">
          <span title="Badge top left"
                :class="[{'is-light': !photoSelectedTags.selected}, 'badge is-top-right']">{{ selected }}</span>
          Selected
        </p>
      </div>
      <div class="level-item pl-2" @click="handleSorting">
        <p :class="[{'is-light is-tag-selected': !photoSelectedTags.unselected}, 'is-clickable status-tag has-text-weight-bold tag is-success is-unselectable is-rounded is-relative pl-2 is-size-6']"
           id="sort-unselected">
          <span title="Badge top left"
                :class="[{'is-light': !photoSelectedTags.unselected}, 'badge is-top-right']">{{ unselected }}</span>
          Unselected
        </p>
      </div>
    </div>
  </nav>

</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "PhotoNavigationTabsInReady",
  computed: {
    ...mapGetters([
      'customerCart',
      'photoSelectedTags',
      "customerPhotoset"
    ]),
    isRetouchebale() {
      return this.customerPhotoset.type === 'retouch'
    },
    downloaded() {
      return this.customerPhotoset.photos?.reduce((count, item) => {
        return count + (item.downloaded ? 1 : 0)
      }, 0)
    },
    selected() {
      return this.customerCart.reduce((count, item) => {
        return count + (item.downloaded ? 0 : 1)
      }, 0)
    },
    unselected() {
      return this.customerPhotoset.photoCount - this.downloaded - this.selected
    },
    retouched() {
      return this.customerPhotoset.photos?.reduce((count, item) => {
        return count + (item.retoucheStatus === 'retouche-ready' ? 1 : 0)
      }, 0)
    },
    unretouched() {
      return this.customerPhotoset.photos?.reduce((count, item) => {
        return count + (item.retoucheStatus === 'retouche-ready' ? 0 : 1)
      }, 0)
    },
  },
  methods: {
    handleSorting(event) {
      switch (event.target.id) {
        case 'sort-all' :
          this.$store.dispatch('setToAll')
          break
        case 'sort-downloaded':
          this.$store.dispatch('setToDownloaded')
          break
        case 'sort-selected':
          this.$store.dispatch('setToSelected')
          break
        case 'sort-unselected':
          this.$store.dispatch('setToUnselected')
          break
        case 'sort-retouched':
          this.$store.dispatch('setToRetouched')
          break
        case 'sort-unretouched':
          this.$store.dispatch('setToUnretouched')
          break
      }
    },
  }

}
</script>

<style>

</style>