<template>
  <ul class="accordion">
    <slot></slot>
  </ul>
</template>

<script>
export default {
    name: "AppAccordion",
    props: ['index'],
    data() {
        return {
        Accordion: {
            count: 0,
            active: this.index,
        },
        };
    },
    provide() {
        return { Accordion: this.Accordion };
    },
    watch: {
        index(newValue) {
            this.Accordion.active = newValue;
        }
    }

}
</script>

<style lang="scss" scoped>

.accordion {
  list-style: none;
  margin: 0;
  padding: 0;
}

.accordion.is-accordion-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

</style>