<template>
  <div ref="lPhoto" tabindex="0" :class="[{'is-active': lPhotoCardOpen}, 'modal']"
       @keydown.esc="setLCardOpenStatusFalse()" @keydown.arrow-right="next()" @keydown.arrow-left="previous()"
       @keydown.space.prevent="manipulateCartItem">
    <div class="modal-background"></div>

    <div class="modal-content" id="photo-card-size-l-wrapper">

      <div class="photo-card-size-l is-unselectable">
        <div class="photo-card-size-l-photo">
          <img :src="lPhotoCardActivePhoto.src.l['0']" alt="" id="photo-card-size-l-image">
        </div>
      </div>
    </div>
    <div id="sidebar-left" class="is-flex is-flex-direction-columns is-justify-content-center is-clickable"
         @click="previous()" v-if="this.photoPosition > 0">
            <span class="material-icons-outlined is-unselectable m-auto has-text-white">
                arrow_back_ios
            </span>
    </div>

    <div id="sidebar-right" class="is-flex is-flex-direction-columns is-justify-content-center is-clickable"
         @click="next()" v-if="this.photoPosition !== this.photos.length - 1">
            <span class="material-icons-outlined is-unselectable m-auto has-text-white">
                arrow_forward_ios
            </span>

    </div>

    <div class="is-flex is-flex-direction-row is-justify-content-space-between l-photo-status-line is-align-items-flex-start">
      <div class="p-1">
        <div class="has-text-weight-semibold white-text-with-border">{{ lPhotoCardActivePhoto.name }}</div>
        <div>
          <app-tag v-show="lPhotoCardActivePhoto.retoucheStatus === 'retouche-ready'" icon="auto_fix_high" text="Retouched" fontSizeClass="is-size-8" tagStatus="has-text-white has-background-gradient-primary" class="app-notification is-lowercase has-text-weight-semibold"/>
          <app-tag v-show="lPhotoCardActivePhoto.retoucheStatus === 'retouche-candidate'" icon="done_all" text="Selected for retouche" fontSizeClass="is-size-8" tagStatus="has-text-primary has-background-white" class="app-notification is-lowercase has-text-weight-semibold"/>

        </div>
      </div>

      <div>
        <span class="material-icons-outlined has-text-white-ter is-clickable l-photo-close-button is-unselectable" @click="setLCardOpenStatusFalse()" aria-label="close">
          close
        </span>
      </div>
    </div>
    <div id="l-photo-buttons-section" class="has-tooltip-arrow has-tooltip-multiline bottomless"
         :data-tooltip="isDownloaded ? 'You cannot change selection for already downloaded photos': null">
      <button v-if="isVisible" id="l-photo-selection-button-ready"
              :class="[ {'is-loading': cartAddRemoveLoading }, buttonClassReady, 'button is-small is-rounded  is-success is-size-7']"
              :disabled="isDownloaded" @click="manipulateCartItem">
                <span class="material-icons-outlined is-size-7 pr-1">
                    shopping_cart
                </span>
        <span>
                  {{ buttonNameStatusReady }}
                </span>
      </button>
      <favorites-button :photo="lPhotoCardActivePhoto" v-if="customerPhotoset.isFavoritesEnabled"/>
    </div>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import FavoritesButton from './FavoritesButton.vue';
import AppTag from "@/components/commons/AppTag";

export default {
  name: "LPhotoCard",
  props: ['photos'],
  components: {
    FavoritesButton,
    AppTag
  },
  computed: {
    ...mapGetters([
      'lPhotoCardOpen',
      'lPhotoCardActivePhoto',
      'customerCart',
      'cartAddRemoveLoading',
      'customerPhotoset'
    ]),
    isDownloaded() {
      return this.lPhotoCardActivePhoto.downloaded
    },
    isVisible() {
      return this.customerPhotoset.downloadablePhotos > 0 || this.lPhotoCardActivePhoto.retoucheStatus == 'retouche-ready'
    },
    isCartItem() {
      const id = this.lPhotoCardActivePhoto.id;
      return this.customerCart.some(item => item.id === id);
    },
    buttonNameStatusReady() {
      return this.isCartItem ? 'Deselect' : 'Select'
    },
    buttonClassReady() {
      return this.isCartItem ? 'is-warning' : 'is-success'
    },
    photoPosition() {
      return this.photos.findIndex(e => e.id === this.lPhotoCardActivePhoto.id)
    }


  },
  methods: {
    setLCardOpenStatusFalse() {
      this.$store.dispatch('setLCardOpenStatus', false)
      this.$store.dispatch('setLPhotoCardActivePhoto', null)
    },
    manipulateCartItem() {
      if (this.isVisible && !this.isDownloaded) {
        const {isCartItem} = this;
        const {id} = this.lPhotoCardActivePhoto;
        if (isCartItem) {
          this.$store.dispatch('removeCartItem', id);
        } else {
          this.$store.dispatch('addCartItem', id);
        }
      }
    },

    next() {
      this.$store.dispatch('nextPhoto', this.photoPosition)
    },
    previous() {
      this.$store.dispatch('previousPhoto', this.photoPosition)
    }
  },
  mounted() {
    this.$refs.lPhoto.focus()
  }
}
</script>

<style scoped>

.white-text-with-border {
  color: white; /* Set text color to white */
  text-shadow:
      -1px -1px 0 #000,
      1px -1px 0 #000,
      -1px  1px 0 #000,
      1px  1px 0 #000; /* Text shadow for visibility */
}



.l-photo-close-button:hover {
  transform: scale(1.1);
}

.l-photo-close-button:active {
  transform: scale(0.9);
}

.l-photo-close-button {
  border-radius: 9999px;
  padding: 3px;
  background-color: rgba(0, 0, 0, 0.4);
}
.l-photo-status-line {
  position: fixed;
  top: 20px;
  width: 100%;
  padding-right: 25px;
  padding-left: 25px;

}

#sidebar-right:hover, #sidebar-left:hover {
  transform: scale(1.2);
}

#sidebar-right:active, #sidebar-left:active {
  transform: scale(.9);
}

.modal-content {
  width: auto !important;
}

.photo-card-size-l-photo {
  position: relative;
}

#l-photo-buttons-section {
  position: absolute;
  bottom: 15px;
  width: auto;

}

#l-photo-selection-button-ready {
  width: 110px;
}

#l-photo-selection-button-review {
  width: 110px;
}


#photo-card-size-l-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  max-height: calc(100vh - 20px);
}

.is-horizontal {
  width: 960px !important;
}

.is-vertical {
  width: 430px !important;
}


#photo-card-size-l-wrapper {
  overflow: hidden;
  /* max-width: 760px; */
  object-fit: contain;
  max-height: calc(100vh - 20px);
  max-width: 100%;
  margin: 0 0; /* remove 20px on both sides of the l photo when resized to smaller size */


}

.photo-card-size-l {
  overflow-y: hidden;
}

@media screen and (min-width: 769px) {
  .photo-card-size-l {
    display: flex;
    flex-direction: row;
  }
}


@media screen and (max-width: 400px) {
  #l-photo-buttons-section {
    bottom: 15px;
    display: flex;
    width: 100%;
    padding-right: 8px;
    padding-left: 8px;
  }

  #l-photo-selection-button-ready, #l-photo-selection-button-review {
    width: 100%;
  }
}

#sidebar-left {
  height: 100%;
  position: absolute;
  left: 0;
  width: 3rem;
}

#sidebar-left:hover {
  background-color: rgba(198, 184, 184, 0.068);
}

#sidebar-right {
  height: 100%;
  position: absolute;
  right: 0;
  width: 3rem;
}

#sidebar-right:hover {
  background-color: rgba(198, 184, 184, 0.068);

}

div.modal-background {
  backdrop-filter: blur(5px);
}


</style>