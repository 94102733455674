<template>
  <app-modal :modal="isSendNowModalActive" :className="className">
    <template v-slot:header>
      <div class="modal-header-icon">
          <span class="material-icons-outlined icon-rounded-primary has-text-primary is-unselectable">
            send
          </span>
      </div>
    </template>
    <template v-slot:body>
      <h2 class="is-size-3 has-text-weight-bold mb-3">Send photos for retouching?</h2>
      <p class="is-size-6">After sending photos for retouching, you cannot change your choice anymore</p>
      <div class="mt-5">
        <div class="mb-1">
          <label class="checkbox is-size-7 mt-2">
            <div class="is-flex is-flex-direction-row is-align-items-center">
                    <span class="is-size-5">
                      <input type="checkbox" v-model="isEmailInputActivated"/>
                    </span>
              <span class="ml-1 is-unselectable">
                        Add email to be notified when retouched photos are ready
                    </span>
            </div>
          </label>
        </div>
        <div class="field" v-show="isEmailInputActivated">
          <div class="control has-icons-right">
            <input
                :class="[{ 'is-danger': invalidEmail }, 'input is-rounded is-small has-text-centered']"
                type="email"
                name="email"
                placeholder="john@example.com"
                v-model="changeStatusToReviewed.email"

                @focus="resetInvalidEmailState"
            />
            <div class="icon is-right" v-if="invalidEmail">
                    <span class="material-icons has-text-danger">
                      error_outline
                    </span>
            </div>
          </div>

          <p class="help is-danger  is-size-7 has-text-centered" :class="{'is-invisible': !invalidEmail }">
            Please enter a valid email address format
          </p>
        </div>
        <!-- <span class="material-icons-outlined is-size-4 hover-effect is-unselectable is-round-icon has-text-primary is-clickable"> add_circle </span>
        <span class="is-size-7">Add email to be notified when retouched photos are ready</span> -->
      </div>
    </template>
    <template v-slot:buttons>
      <button class="button is-light is-rounded has-text-weight-bold" @click="closeSendNowModal">Cancel</button>
      <button :class="[{'is-loading': isLoading }, 'button is-primary is-rounded has-text-weight-bold ml-4']"
              @click="sendNow">Send
      </button>
    </template>
  </app-modal>
</template>

<script>
import AppModal from './AppModal.vue';
import {isValidEmail} from './../../utils'
import {mapGetters} from 'vuex';

export default {
  name: "SendNowModal",
  data() {
    return {
      className: "send-now-modal",
      isEmailInputActivated: false,
      invalidEmail: false,
      changeStatusToReviewed: {
        status: "reviewed",
        email: null
      }
    }
  },
  components: {
    AppModal
  },
  computed: {
    ...mapGetters([
      "isSendNowModalActive",
      "isLoading",
      "customerPhotoset"
    ]),
  },
  methods: {
    closeSendNowModal() {
      this.$store.dispatch('changeSendNowModalStatus', false)
    },
    validateEmail() {
      this.invalidEmail = !isValidEmail(this.changeStatusToReviewed.email)
    },
    sendNow() {
      if (this.isEmailInputActivated) {
        this.validateEmail();
        if (this.invalidEmail) return;
      }
      const changeStatusToReviewedObj = (this.isEmailInputActivated && this.changeStatusToReviewed.email) ? this.changeStatusToReviewed : {status: "reviewed"};
      this.$store.dispatch('setPhotosetStatusToReviewed', changeStatusToReviewedObj)
    },
    resetInvalidEmailState() {
      this.invalidEmail = false
    }
  },
  created() {
    const customerEmail = this.customerPhotoset?.customerEmail;
    if (customerEmail) {
      this.isEmailInputActivated = true;
      this.changeStatusToReviewed.email = customerEmail;
    }
  }

}
</script>

<style>

.modal-header-icon {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: auto;
}

.icon-rounded-primary {
  border-radius: 50%;
  border: 3px solid #605DEC;
  font-size: 64px !important;
  padding: 10px;
}

@media screen and (min-width: 769px) {
  .send-now-modal {
    width: 512px;
  }
}


@media screen and (max-width: 768px) {
  .send-now-modal {
    width: 100%;
  }
}

</style>