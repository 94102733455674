<template>
  <div ref="lPhoto" tabindex="0" :class="[{'is-active': lPhotoCardOpen}, 'modal']"
       @keydown.esc="setLCardOpenStatusFalse()" @keydown.arrow-right="next()" @keydown.arrow-left="previous()"
       @keydown.space.prevent="manipulateRetoucheStatus">
    <div class="modal-background"></div>

    <div class="modal-content" id="photo-card-size-l-wrapper">

      <div class="photo-card-size-l is-unselectable">
        <div class="photo-card-size-l-photo">
          <img :src="lPhotoCardActivePhoto.src.l['0']" alt="" id="photo-card-size-l-image">
        </div>

      </div>
    </div>
    <div id="sidebar-left" class="is-flex is-flex-direction-columns is-justify-content-center is-clickable"
         @click="previous()" v-if="this.photoPosition > 0">
            <span class="material-icons-outlined is-unselectable" style="margin: auto; color: white;">
                arrow_back_ios
            </span>
    </div>

    <div id="sidebar-right" class="is-flex is-flex-direction-columns is-justify-content-center is-clickable"
         @click="next()" v-if="this.photoPosition !== this.photos.length - 1">
            <span class="material-icons-outlined is-unselectable" style="margin: auto; color: white;">
                arrow_forward_ios
            </span>

    </div>
    <button class="modal-close is-large" aria-label="close" @click="setLCardOpenStatusFalse()"></button>

    <div id="l-photo-buttons-section" v-if="this.customerPhotoset.status === 'review'">
      <button id="l-photo-selection-button-review"
              :class="[ {'is-loading': cartAddRemoveLoading }, buttonClassReview, 'button is-small is-rounded is-success is-size-7']"
              @click="manipulateRetoucheStatus">
                <span class="material-icons-outlined is-size-7 pr-1">
                    done_all
                </span>
        {{ buttonNameStatusReview }}
      </button>
      <favorites-button :photo="lPhotoCardActivePhoto" v-if="customerPhotoset.isFavoritesEnabled"/>
    </div>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import FavoritesButton from "@/components/photoset/FavoritesButton";

export default {
  name: "LPhotoCard",
  props: ['photos'],
  components: {FavoritesButton},
  computed: {
    ...mapGetters([
      'lPhotoCardOpen',
      'lPhotoCardActivePhoto',
      'customerCart',
      'cartAddRemoveLoading',
      'customerPhotoset',
    ]),
    isRetoucheCandidate() {
      const id = this.lPhotoCardActivePhoto.id;
      return this.customerPhotoset.photos.some(item => item.id === id && item.retoucheStatus === 'retouche-candidate');
    },

    buttonNameStatusReview() {
      return this.isRetoucheCandidate ? 'Deselect' : 'Select'
    },
    buttonClassReview() {
      return this.isRetoucheCandidate ? 'is-warning' : 'is-success'
    },
    photoPosition() {
      return this.photos.findIndex(e => e.id === this.lPhotoCardActivePhoto.id)
    }


  },
  methods: {
    setLCardOpenStatusFalse() {
      this.$store.dispatch('setLCardOpenStatus', false)
      this.$store.dispatch('setLPhotoCardActivePhoto', null)
    },
    manipulateRetoucheStatus() {
      const {customerPhotoset} = this;
      const {id} = this.lPhotoCardActivePhoto;
      if (customerPhotoset.status === 'review') {
        if (!this.isRetoucheCandidate) {
          this.$store.dispatch('changePhotoRetoucheStatusToRetoucheCandidate', id);
        } else if (this.isRetoucheCandidate) {
          this.$store.dispatch('changePhotoRetoucheStatusToUnassigned', id);
        }
      }
    },
    next() {
      this.$store.dispatch('nextPhoto', this.photoPosition)
    },
    previous() {
      this.$store.dispatch('previousPhoto', this.photoPosition)
    }
  },
  mounted() {
    this.$refs.lPhoto.focus()
  }
}
</script>

<style scoped>

#sidebar-right:hover, #sidebar-left:hover {
  transform: scale(1.2);
}

.modal-content {
  width: auto !important;
}

.photo-card-size-l-photo {
  position: relative;
}

#l-photo-buttons-section {
  position: absolute;
  bottom: 15px;
  width: auto;

}

#l-photo-selection-button-ready {
  width: 110px;
}

#l-photo-selection-button-review {
  width: 110px;
}


#photo-card-size-l-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  max-height: calc(100vh - 20px);
}

.is-horizontal {
  width: 960px !important;
}

.is-vertical {
  width: 430px !important;
}


#photo-card-size-l-wrapper {
  overflow: hidden;
  /* max-width: 760px; */
  object-fit: contain;
  max-height: calc(100vh - 20px);
  max-width: 100%;
  margin: 0 0; /* remove 20px on both sides of the l photo when resized to smaller size */


}

.photo-card-size-l {
  overflow-y: hidden;
}

@media screen and (min-width: 769px) {
  .photo-card-size-l {
    display: flex;
    flex-direction: row;
  }
}


@media screen and (max-width: 400px) {
  #l-photo-buttons-section {
    bottom: 15px;
    display: flex;
    width: 100%;
    padding-right: 8px;
    padding-left: 8px;
  }

  #l-photo-selection-button-ready, #l-photo-selection-button-review {
    width: 100%;
  }
}

#sidebar-left {
  height: 100%;
  position: absolute;
  left: 0;
  width: 3rem;
}

#sidebar-left:hover {
  background-color: rgba(198, 184, 184, 0.068);
}

#sidebar-right {
  height: 100%;
  position: absolute;
  right: 0;
  width: 3rem;
}

#sidebar-right:hover {
  background-color: rgba(198, 184, 184, 0.068);

}

div.modal-background {
  backdrop-filter: blur(5px);
}

</style>