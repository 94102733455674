<template>
  <div id="accordion-photoset-lifecycle" class="p-5 mx-2 has-background-white is-relative">
    <app-accordion :index="accordionIndex" v-if="accordionIndex !== undefined " :class="accordionClass">
      <app-accordion-item v-for="(item, index) in photosetLifeCycleStructureArray" :key="index"
                      :class="[{'is-invisible-below-849': item.status !== 'current' }, 'is-accordion-lifecycle-item is-flex-1']"
      >
        <template v-slot:accordion-trigger>
          <div
              class="is-flex is-flex-direction-row	is-justify-content-flex-start	is-align-items-start is-unselectable is-unclickable is-flex-wrap-nowrap mb-5">
            <span
                class="circle is-size-6 mr-2 has-background-gradient-primary has-text-white has-text-weight-bold is-borderless"
                v-if="item.status === 'current'">{{ index + 1 }}</span>
            <span
                class="circle is-size-7 mr-2 has-background-grey-lighter has-text-black has-text-weight-semibold is-borderless"
                v-if="item.status === 'future'">{{ index + 1 }}</span>
            <span
                class="circle is-size-7 mr-2 has-background-success has-text-white has-text-weight-semibold is-borderless"
                v-if="item.status === 'past'">
                <span class="material-icons-outlined is-size-6">
                  check
                </span>
              </span>

            <span>
                <div class="is-size-6"
                     :class="item.status === 'current' ? 'grey-900 has-text-weight-bold' : 'grey-600 has-text-weight-semibold'">{{
                    item.title
                  }}</div>
                <div class="is-size-7 grey-600" v-if="item.status === 'future'">{{ item.subtitle }}</div>
              </span>
          </div>
        </template>
        <template v-slot:accordion-content>
          <photoset-life-cycle-details-items :currentStep="currentStep" v-if="item.status === 'current'"
                                             class="has-text-weight-semibold"/>
          <div v-if="item.status === 'past'">
            <div class="is-size-7 grey-600" v-for="(line, index) in item.subtitle" :key="index">{{ line }}</div>
          </div>

        </template>

      </app-accordion-item>

    </app-accordion>
  </div>
</template>

<script>
import AppAccordion from '../../commons/accordion/AppAccordion';
import AppAccordionItem from '../../commons/accordion/AppAccordionItem'
import {mapGetters} from "vuex";
import {photosetLifeCycleStructRetouche, photosetLifeCycleStructDownload} from "@/data/photosetLifeCycleStruct";
import PhotosetLifeCycleDetailsItems from './PhotosetLifeCycleDetailsItems.vue';

export default {
  name: "PhotosetLifeCycle",
  components: {
    AppAccordion,
    AppAccordionItem,
    PhotosetLifeCycleDetailsItems
  },
  props: ['accordionClass'],
  computed: {
    ...mapGetters([
      'customerPhotoset',
      'customerCart'
    ]),
    photosetLifeCycleStructureArray() {
      const array = this.customerPhotoset?.type === 'download' ? photosetLifeCycleStructDownload : photosetLifeCycleStructRetouche
      switch (this.currentStep) {
        case 'readyDownload':
          return array
        case 'downloadedDownload':
          array[0].status = 'past'
          array[0].subtitle = this.readySubtitle
          array[1].status = 'current'
          return array

        case 'review':
          return array
        case 'reviewed':
          array[0].status = 'past'
          array[0].subtitle = this.reviewSubtitle
          array[1].status = 'current'
          return array
        case 'readyRetouch':
          array[0].status = 'past'
          array[0].subtitle = this.reviewSubtitle
          array[1].status = 'past'
          array[1].subtitle = this.reviewedSubtitle
          array[2].status = 'current'
          return array
        case 'downloadedRetouch':
          array[0].status = 'past'
          array[0].subtitle = this.reviewSubtitle
          array[1].status = 'past'
          array[1].subtitle = this.reviewedSubtitle
          array[2].status = 'past'
          array[2].subtitle = this.readySubtitle
          array[3].status = 'current'
          return array
        default:
          return array
      }
    },
    isCompletelyDownloaded() { // DRY MIXIN
      const photosDownloaded = this.customerPhotoset.photos.reduce((acc, curr) => {
        if (curr.retoucheStatus !== 'retouche-ready' && curr.downloaded) {
          return ++acc
        } else {
          return acc
        }
      }, 0)

      const downloadablePhotosInPhotosetConfiguration = this.customerPhotoset?.downloadAll ? this.customerPhotoset.photoCount :
          ((this.customerPhotoset.downloadablePhotos >= this.customerPhotoset.photoCount) ? this.customerPhotoset.photoCount : this.customerPhotoset.downloadablePhotos)
      const downloadableUnretouchedPhotos = this.customerPhotoset.photos.reduce((acc, curr) => {
        if (curr.retoucheStatus !== 'retouche-ready') {
          return ++acc
        } else {
          return acc
        }
      }, 0)

      const downloadablePhotos = (downloadableUnretouchedPhotos >= downloadablePhotosInPhotosetConfiguration) ? downloadablePhotosInPhotosetConfiguration : downloadableUnretouchedPhotos
      const isAllPhotosDownloaded = (photosDownloaded === downloadablePhotos)

      if (this.customerPhotoset.type === 'retouch') {
        const isRetoucheReadyDownloaded = !this.customerPhotoset.photos.some(e => (e.retoucheStatus === 'retouche-ready' && !e.downloaded))
        return (isRetoucheReadyDownloaded && isAllPhotosDownloaded)
      } else {
        return isAllPhotosDownloaded
      }
    },
    accordionIndex() {
      return this.photosetLifeCycleStructureArray?.findIndex(e => e.status === 'current')
    },
    currentStep() {
      switch (this.customerPhotoset?.type) {
        case 'retouch':
          return this.customerPhotoset?.status === 'ready' ? (this.isCompletelyDownloaded ? 'downloadedRetouch' : 'readyRetouch') : this.customerPhotoset.status
        case 'download':
          return this.isCompletelyDownloaded ? 'downloadedDownload' : 'readyDownload'
        default:
          return null
      }
    },
    downloadedPhotos() {
      return this.customerPhotoset.photos?.reduce((count, item) => {
        return count + (item.downloaded ? 1 : 0)
      }, 0)
    },
    downloadedRetouchedPhotos() {
      return this.customerPhotoset.photos?.reduce((count, item) => {
        return count + ((item.downloaded && item.retoucheStatus === 'retouche-ready') ? 1 : 0)
      }, 0)
    },
    downloadedUnretouchedPhotos() {
      return this.customerPhotoset.photos?.reduce((count, item) => {
        return count + ((item.downloaded && item.retoucheStatus !== 'retouche-ready') ? 1 : 0)
      }, 0)
    },

    reviewSubtitle() {
      const retoucheCandidates = this.customerPhotoset.photos?.reduce((count, item) => {
        return count + (item.retoucheStatus === 'retouche-candidate' ? 1 : 0)
      }, 0)
      return retoucheCandidates ? [`${retoucheCandidates} photos selected for retouche`] : ["Photos selected for retouche"]
    },
    reviewedSubtitle() {
      const retoucheReady = this.customerPhotoset.photos?.reduce((count, item) => {
        return count + (item.retoucheStatus === 'retouche-ready' ? 1 : 0)
      }, 0)
      return retoucheReady ? [`${retoucheReady} retouched photos uploaded`] : ["Retouched photos uploaded"]
    },
    readySubtitle() {
      const photosetType = this.customerPhotoset?.type

      switch (photosetType) {
        case 'retouch':
          return [
            `${this.downloadedRetouchedPhotos} retouched photos selected`,
            this.downloadedUnretouchedPhotos > 0 ? `${this.downloadedUnretouchedPhotos} unretouched photos selected` : '',
          ]
        case 'download':
          return [`${this.downloadedPhotos} photos selected`]
        default:
          return 'Photos selected';
      }

    },
    isCartEmpty() {
      return (this.customerCart.length === 0)
    },
    isCartFull() {
      return (this.customerCart.length === this.downloadable)
    },
    isAllPhotosDownloadable() {
      return (this.customerPhotoset?.downloadAll || this.customerPhotoset?.downloadablePhotos >= this.customerPhotoset?.photoCount)
    },
    downloadable() {
      return this.customerPhotoset?.downloadAll ? this.customerPhotoset.photoCount :
          ((this.customerPhotoset.downloadablePhotos >= this.customerPhotoset.photoCount) ? this.customerPhotoset.photoCount : this.customerPhotoset.downloadablePhotos)
    },

  }
}
</script>

<style>
#accordion-photoset-lifecycle {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  /*0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);*/
}

.circle {
  display: inline-flex;
  align-items: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
}

.is-borderless {
  border-style: none !important;
}

@media screen  and (max-width: 849px) {
  .is-invisible-below-849 {
    display: none;
  }
}
</style>