import { createRouter, createWebHistory } from "vue-router";
import MainView from '../views/MainView'
import CheckoutView from '../views/CheckoutView'
import store from '../store'

const routes = [
    {
        path: '/access/:psId',
        redirect: to => {
            // The function receives the target route as the argument.
            // We return a redirect path here.
            return '/' + to.params.psId;
        }
    },
    {
        name: "Main",
        path: '/:psId',
        component: MainView,
        props: true
    },
    {
        name: "Checkout",
        path: '/:psId/checkout',
        component: CheckoutView,
        props: true,
        beforeEnter: (to, from, next) => {
            if (store.getters.customerCart.length > 0) {
              next()
            } else {
              const psId = to.params.psId // Get the psId prop from the URL
              next(`/${psId}`) // Redirect to the access page with the psId prop
            }
        }        
    },
    {
        name: "NotFound",
        path: '/:psId',
        component:  () => import(/* webpackChunkName: "NotFoundView" */ '../views/NotFoundView'),
    },
    {
        name: "InternalServerError",
        path: '/:psId',
        component:  () => import(/* webpackChunkName: "InternalServerErrorView" */ '../views/InternalServerErrorView'),
    },
    {
        name: "PageNotFound",
        path: '/:pathMatch(.*)*',
        component: () => import(/* webpackChunkName: "PageNotFoundView" */ '../views/PageNotFoundView'),
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
  });
  
  
  
  export default router;