<template>
  <svg :width="width" height="100%" viewBox="0 0 194 98" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M156.659 73.6711C155.419 71.8808 153.399 70.8251 151.242 70.8251H132.513L126.638 62.1952C125.398 60.3591 123.333 59.3033 121.129 59.3033H93.1282L90.374 48.975C90.1904 48.2865 89.5018 47.8733 88.8133 48.057L85.0492 49.0668C84.3606 49.2504 83.9475 49.939 84.1311 50.6276L90.8789 75.7826L84.0393 89.7832L89.8231 92.6292L97.1677 77.4811C97.2595 77.2515 97.3513 77.022 97.3972 76.7925H151.196C151.38 76.7925 151.563 76.8843 151.701 77.0679L164.646 96.072L169.557 92.721L156.659 73.6711ZM121.129 62.8838C122.093 62.8838 123.011 63.3887 123.562 64.1691L128.015 70.7333H96.2037L94.0922 62.8379H121.129V62.8838Z" fill="#ADB6C8"/>
    <path d="M185.761 97.1737H10.5479C24.273 89.0028 42.5885 82.9436 60.9957 83.219C82.9835 83.5403 104.099 88.6356 109.837 91.4357C125.582 90.4718 145.596 86.3405 156.062 86.6618C164.967 86.9831 177.912 89.3701 185.761 97.1737Z" fill="#FCF9B3"/>
    <path d="M193.152 97.1737H185.761H10.5479H1" stroke="#D5DAE8" stroke-width="0.918069" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M70.865 88.452C69.901 88.452 69.0289 87.7635 68.8453 86.7995L52.5954 2.47485C52.3659 1.37317 53.1004 0.271483 54.248 0.0419655C55.3496 -0.187552 56.4513 0.546904 56.6808 1.69449L72.9307 85.9732C73.1602 87.0749 72.4257 88.1766 71.2781 88.4061C71.0945 88.452 71.0027 88.452 70.865 88.452Z" fill="#ADB6C8"/>
    <path d="M43.2771 45.1651C43.323 44.8896 43.4148 44.6601 43.5066 44.3847C43.5525 44.7978 43.5525 45.211 43.5984 45.6241C43.5066 45.4405 43.3689 45.3028 43.2771 45.1651Z" fill="white"/>
    <path d="M79.7703 38.0041C79.7244 38.05 79.7244 38.1418 79.7244 38.1877C79.6785 38.05 79.6326 37.9123 79.5867 37.7287C79.6326 37.8205 79.7244 37.9123 79.7703 38.0041Z" fill="white"/>
    <path d="M142.681 39.3253C151.453 39.3253 158.564 32.2144 158.564 23.4427C158.564 14.671 151.453 7.56009 142.681 7.56009C133.909 7.56009 126.798 14.671 126.798 23.4427C126.798 32.2144 133.909 39.3253 142.681 39.3253Z" fill="#F1EB54"/>
    <path d="M36.5752 93.0883C38.3654 90.334 42.0836 90.9767 43.5984 91.5275" stroke="#ADB6C8" stroke-width="0.918069" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M61.822 90.2423C65.5861 87.9012 70.4978 89.6914 70.865 91.4817" stroke="#ADB6C8" stroke-width="0.918069" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M77.3374 94.6949C77.9801 93.7768 79.449 93.0883 80.4129 93.7309" stroke="#ADB6C8" stroke-width="0.918069" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M137.012 94.0981C140.822 92.8587 141.006 93.6391 141.97 94.0981" stroke="#ADB6C8" stroke-width="0.918069" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M149.911 93.0882C150.324 92.3538 152.344 92.6751 152.344 92.6751" stroke="#ADB6C8" stroke-width="0.918069" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M108.827 25.2889C104.971 23.3609 100.473 22.6265 95.8825 23.4986C87.9412 24.9676 81.8819 30.7514 79.7703 38.0041C79.7244 38.05 79.7244 38.1418 79.7244 38.1877C79.6785 38.05 79.6326 37.9123 79.5867 37.7287C74.9964 31.899 67.3764 28.6857 59.5269 30.1546C51.6774 31.5776 45.71 37.2697 43.5066 44.3847C43.5525 44.7978 43.5525 45.211 43.5984 45.6241C43.5066 45.4864 43.3689 45.3028 43.2771 45.1651C38.7327 38.8763 30.8832 35.4335 22.7583 36.9024C18.4892 37.6828 14.7252 39.7485 11.8792 42.5945C16.8367 22.7183 32.9947 6.51437 54.4775 2.61257C76.144 -1.38103 97.1677 8.2128 108.827 25.2889Z" fill="#83A6FF"/>
    <path d="M79.5868 37.7287C74.9964 31.899 67.3764 28.6857 59.5269 30.1546C51.6775 31.5777 45.71 37.2697 43.5066 44.3847C41.6705 21.7543 45.9395 4.17329 54.5235 2.61258C63.0615 1.05186 73.2521 15.9705 79.5868 37.7287Z" fill="#FF66A9"/>
</svg>

</template>

<script>
export default {
    name: "BeachLounger",
    props: ['width']
}
</script>

<style>

</style>