<template>
    <button  class="button has-background-gradient-primary is-small is-rounded has-text-white is-size-7 has-text-weight-bold  pl-2" @click="addAllItemsToCart">
          <span class="material-icons-outlined is-size-7 pr-2">
              add
          </span>
          <span>
              Add all Photos to the Cart
          </span>
          
      </button>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  export default {
      name: "AddAllItemsToCartButton",
      computed: {
        ...mapGetters([
            'customerPhotoset'
        ]),
      },
      methods: {
        addAllItemsToCart() { // DRY MIXIN
        this.$store.dispatch('addAllItemsToCart', this.customerPhotoset?.id)
        }
      }
  
  }
  </script>
  
  <style lang="scss">
  
  </style>