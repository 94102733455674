<template>
  <progress class="progress is-primary" :value="progress" :max="max"></progress>
</template>

<script>
export default {
    name: "CartProgressBar",
    props: ['progress', 'max']
}
</script>

<style>

</style>