<template>
  <button :disabled="customerCart.length === 0"
          class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
          :class="[{'is-loading': downloadLoading }, 'button has-background-gradient-primary has-text-white is-small is-rounded is-size-7 has-text-weight-bold  pl-2']"
          @click="downloadAll()">
            <span class="material-icons-outlined is-size-7 pr-2" v-if="isIconEnabled">
                file_download
              </span>
    <span>
          {{ buttonLabel }}
        </span>
  </button>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "DownloadButton",
  props: {
    buttonLabel: {
      type: String,
      required: true,
      default: 'Download'
    },
    isIconEnabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      "customerCart",
      "downloadLoading"
    ])
  },
  methods: {
    downloadAll() {
      this.$store.dispatch('downloadAll')
    }
  }

}
</script>

<style>

</style>