<template>
  <div class="photo-card-size-m" >
    <figure class="photo-card-size-m-image mb-2">
      <Skeletor height="300" class="is-border-radius-9"/>
    </figure>
      <div class="photo-card-size-m-filename has-text-centered is-size-5">
          <Skeletor width="50%"/>
      </div>
  </div>
</template>

<script>
import { Skeletor } from "vue-skeletor";
export default {
    name: "MPhotoCardSkeleton",
    components: {
      Skeletor
    }
}
</script>

<style>

</style>