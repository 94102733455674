import { createApp } from 'vue'
import LazyLoadDirective from "./directives/LazyLoadDirective";
import App from './App.vue'
import router from "./router";
import store from "./store";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
    // You can set your default options here
    position: POSITION.TOP_RIGHT,
    timeout: 5000
};

require("./assets/styles/main.scss");
createApp(App).use(store).use(router).use(Toast, options).directive("lazyload", LazyLoadDirective).mount('#app')
