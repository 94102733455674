import { mapGetters } from "vuex"

export default {

    computed: {
        ...mapGetters([
            'customerCart',
            'customerPhotoset'
        ]),
        retouchedPhotosTotal() { // all the uploaded retouched photos, there is no restrcition on amount of retouched uploaded photos as it is decided by photographer, only on allowed photos selection for retouche
            return this.customerPhotoset.photos?.reduce((count, item) => {
                return count + (item.retoucheStatus === 'retouche-ready' ? 1 : 0)
            }, 0)
        },
        retoucheReadyPhotosInCart() { // retouched photos put to cart 
            return this.customerCart?.reduce((count, item) => {
                return count + (item.retoucheStatus === 'retouche-ready' ? 1 : 0)
            }, 0)
        },
        unretouchedPhotosTotal() { // all the unretouched photos including selected for retouch
            return this.customerPhotoset.photos?.reduce((count, item) => {
                return count + (item.retoucheStatus !== 'retouche-ready' ? 1 : 0)
            }, 0)
        },
        downloadableUnretouchedPhotosTotal() { // the number of unretouched photos which can be downloaded, it should be calculated 
            return this.isAllPhotosDownloadable 
                ? this.unretouchedPhotosTotal
                : Math.min(this.downloadable, this.unretouchedPhotosTotal);

        },
        downloadableUnretouchedPhotosInCart() {
            return this.customerCart?.reduce((count, item) => {
                return count + (item.retoucheStatus !== 'retouche-ready' ? 1 : 0)
            }, 0)
        },
        isCartEmpty() {
            return (this.customerCart.length === 0)
        },
        isCartFull() {
            return (this.customerCart.length  === this.retouchedPhotosTotal + this.downloadableUnretouchedPhotosTotal)
        },
        isAllPhotosDownloadable() {
            const { downloadAll, downloadablePhotos, photoCount } = this.customerPhotoset || {};
            return downloadAll || (downloadablePhotos >= photoCount);
        },
        downloadable() {
            const { customerPhotoset } = this;
        
            if (!customerPhotoset) {
                return 0;
            }
        
            const { downloadAll, downloadablePhotos, photoCount } = customerPhotoset;
        
            return downloadAll || (downloadablePhotos >= photoCount) 
                   ? photoCount 
                   : downloadablePhotos;
        }
    }
}