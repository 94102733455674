<template>
  <transition name="pop">
    <div :class="[{'is-active': modal}, 'modal']"  v-if="modal">
      <div class="modal-background app-modal-background"></div>
      <div  :class="className" class="app">
        <div class="card is-flex is-flex-direction-column is-align-items-center is-border-radius-9 p-4">
          <div class="modal-header m-4">
          <slot name="header"></slot>
          </div>
          <div class="modal-body m-4" :style="styleNameBody">
            <slot name="body"></slot>
          </div>
          <div class="modal-buttons m-4">
            <slot name="buttons"></slot>
          </div>
        </div>
        <slot name="notification"></slot>
      </div>
    </div>  
  </transition>
</template>

<script>
export default {
    name: "AppModal",
    props: ['modal', 'className', 'styleNameBody'],
}
</script>

<style>

.pop-enter-from {
  opacity: 0;
}

.pop-enter-to {
  opacity: 1;
}

.pop-enter-active {
  transition: all 0.1s ease-out;
}

.pop-leave-from {
  opacity: 1;
}

.pop-leave-to {
  opacity: 0;
}

.pop-leave-active {
  transition: all 0.2s ease;
}

.modal-body {
  text-align: center;
}

.app-modal-background {
  opacity: 0.5 !important;
}



.app-modal {
  position: relative;
}
@media only screen and (max-width: 768px) {
  .app-modal {
    width: 100%;
  }
}

@media only screen and (min-width: 769px) {
  .app-modal {
    width: 384px;
  }
}
</style>