<template>
    <button  class="button has-background-gradient-primary is-small is-rounded has-text-white is-size-7 has-text-weight-bold  pl-2" @click="addAllRetoucheReadyItemsToCart">
          <span class="material-icons-outlined is-size-7 pr-2">
              add
          </span>
          <span>
              Add all Retouched to Cart
          </span>
          
      </button>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  export default {
      name: "AddAllRetoucheReadyItemsToCart",
      computed: {
        ...mapGetters([
            'customerPhotoset'
        ]),
      },
      methods: {
        addAllRetoucheReadyItemsToCart() {
            this.$store.dispatch('addAllRetoucheReadyItemsToCart', this.customerPhotoset?.id)
        }
      }
  
  }
  </script>
  
  <style lang="scss">
  
  </style>