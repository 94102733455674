import axios from "axios";
import { useToast } from 'vue-toastification'
import { deleteCookie, getCookie } from "../../utils";


const toast = useToast()

const initialState = {
    customerPhotoset: null,
    customerPhotosetPhotosLoading: false,
    customerCart: [],
    lPhotoCardOpen: false,
    lPhotoCardActivePhoto: null,
    cartAddRemoveLoading: false,
    photoSelectedTags: {
        all: true,
        downloaded: false,
        selected: false,
        unselected: false,
        retouched: false,
        unretouched: false
    },
    sortedPhotos: [],
    cartSectionGridLayout: true,
    downloadLoading: false,
    activePhotoSource: 'photosetPhotos', // 'photosetPhotos', 'sortedPhotos'
    isSendNowModalActive: false,
    isLoading: false
};

const state = initialState

const mutations = {
    UPDATE_CUSTOMER_PHOTOSET(state, payload) {
        state.customerPhotoset = payload
    },
    SET_CUSTOMER_PHOTOSET_PHOTOS_LOADING_STATE(state, status) {
        state.customerPhotosetPhotosLoading = status
    },
    UPDATE_CUSTOMER_PHOTOSET_PHOTOS(state, payload) {
        const hasRetoucheReady = payload.some(photo => photo.retoucheStatus === 'retouche-ready');

        if (hasRetoucheReady) {
            payload.sort((a, b) => {
                // First, sort by retoucheStatus
                if (a.retoucheStatus === 'retouche-ready' && b.retoucheStatus !== 'retouche-ready') {
                  return -1;
                }
                if (b.retoucheStatus === 'retouche-ready' && a.retoucheStatus !== 'retouche-ready') {
                  return 1;
                }
              
                // Then, sort by name
                return a.name.localeCompare(b.name);
            });
        }
        state.customerPhotoset.photos = payload
    },
    ADD_CART_ITEM(state, photoId) {
        let photo = state.customerPhotoset.photos.find(photo => photo.id === photoId)
        state.customerCart.push(photo)
    },
    REMOVE_CART_ITEM(state, photoId) {
        const i = state.customerCart.findIndex(photo => photo.id === photoId)
        if (i > - 1) {
            state.customerCart.splice(i, 1)
        }
    },
    UPDATE_CART_ITEMS( state, payload ) {
        state.customerCart = payload
    },
    SET_L_PHOTOCARD_OPEN(state, status) {
        state.lPhotoCardOpen = status
    },
    // Active Photo in View receives the photo from lPhotoCardActivePhoto
    SET_L_PHOTOCARD_ACTIVE_PHOTO(state, activePhoto) {
        state.lPhotoCardActivePhoto = activePhoto
    },
    ADD_REMOVE_CART_PENDING(state) {
        state.cartAddRemoveLoading = true
    },
    ADD_REMOVE_CART_DONE(state) {
        state.cartAddRemoveLoading = false
    },
    SET_TO_ALL(state) {
        Object.keys(state.photoSelectedTags).forEach(key => {
            state.photoSelectedTags[key] = false
        })
        state.photoSelectedTags.all = true
        state.sortedPhotos = []
    },
    SET_TO_DOWNLOADED(state) {
        Object.keys(state.photoSelectedTags).forEach(key => {
            state.photoSelectedTags[key] = false
        })
        state.photoSelectedTags.downloaded = true
        state.sortedPhotos = state.customerPhotoset.photos.filter(item => item.downloaded === true)
    },
    SET_TO_SELECTED(state) {
        Object.keys(state.photoSelectedTags).forEach(key => {
            state.photoSelectedTags[key] = false
        })
        state.photoSelectedTags.selected = true
        state.sortedPhotos = state.customerCart.filter(item => item.downloaded !== true)

    },
    SET_TO_UNSELECTED(state) {
        Object.keys(state.photoSelectedTags).forEach(key => {
            state.photoSelectedTags[key] = false
        })
        state.photoSelectedTags.unselected = true

        const cartItemIds = new Set(state.customerCart.map(item => item.id));
        const sortedPhotos = [];
            for (let i = 0; i < state.customerPhotoset.photos.length; i++) {
            const photo = state.customerPhotoset.photos[i];
            if (!cartItemIds.has(photo.id)) {
                sortedPhotos.push(photo);
            }
        }
        state.sortedPhotos = sortedPhotos

    },
    SET_TO_RETOUCHED(state) {
        Object.keys(state.photoSelectedTags).forEach(key => {
            state.photoSelectedTags[key] = false
        })
        state.photoSelectedTags.retouched = true
        state.sortedPhotos = state.customerPhotoset.photos.filter(item => item.retoucheStatus === 'retouche-ready')
    },
    SET_TO_UNRETOUCHED(state) {
        Object.keys(state.photoSelectedTags).forEach(key => {
            state.photoSelectedTags[key] = false
        })
        state.photoSelectedTags.unretouched = true
        state.sortedPhotos = state.customerPhotoset.photos.filter(item => item.retoucheStatus !== 'retouche-ready')

    },
    SET_TO_SELECTED_RETOUCHE_CANDIDATE(state) {
        Object.keys(state.photoSelectedTags).forEach(key => {
            state.photoSelectedTags[key] = false
        })
        state.photoSelectedTags.selected = true
        state.sortedPhotos = state.customerPhotoset.photos?.reduce((accumulator, currentItem) => {
            if (currentItem.retoucheStatus === 'retouche-candidate') {
              accumulator.push(currentItem);
            }
            return accumulator;
          }, []);
    },
    SET_TO_UNSELECTED_RETOUCHE_CANDIDATE(state) {
        Object.keys(state.photoSelectedTags).forEach(key => {
            state.photoSelectedTags[key] = false
        })
        state.photoSelectedTags.unselected = true
        state.sortedPhotos = state.customerPhotoset.photos?.reduce((accumulator, currentItem) => {
            if (currentItem.retoucheStatus !== 'retouche-candidate') {
              accumulator.push(currentItem);
            }
            return accumulator;
          }, []);
    },
    EMPTY_CART(state) {
        state.customerCart = state.customerCart.filter(item => item.downloaded === true)
    },
    UPDATE_CART_ITEM_TO_DOWNLOADED(state, photoId) {
        const objIndex = state.customerCart.findIndex(item => item.id === photoId)
        if (objIndex > -1 ) {
            state.customerCart[objIndex].downloaded = true
        }

    },
    UPDATE_ALL_CART_ITEM_TO_DOWNLOADED(state) {
        state.customerCart.forEach(item => item.downloaded = true)
    },
    SET_CARD_SECTION_GRID_LAYOUT(state, width) {
        if (width < 769 ) {
            state.cartSectionGridLayout = false
        } else {
            state.cartSectionGridLayout = true
        }
    },
    DOWNLOAD_LOADING(state, payload) {
        state.downloadLoading = payload
    },
    SET_ACTIVE_PHOTOS_SOURCE_TO_PHOTOSET_PHOTOS(state) {
        state.activePhotoSource = 'photosetPhotos'
    },
    SET_ACTIVE_PHOTOS_SOURCE_TO_SORTED_PHOTOS(state) {
        state.activePhotoSource = 'sortedPhotos'
    },
    UPDATE_PHOTO_RETOUCHE_STATUS(state, { photoId, bodyContent }) {
        const photoIndex = state.customerPhotoset.photos.findIndex(e => e.id === photoId)
        if (photoIndex > -1) {
            console.log(state.customerPhotoset.photos[photoIndex].retoucheStatus)
            state.customerPhotoset.photos[photoIndex].retoucheStatus = bodyContent.retoucheStatus
            
        }
    },
    CHANGE_SEND_NOW_MODAL_STATUS(state, status) {
        state.isSendNowModalActive = status
    },
    SET_PHOTOSET_STATUS_TO_REVIEWED(state, obj) {
        if (obj.email) {
            state.customerPhotoset.status = obj.status
            state.customerPhotoset.customerEmail = obj.email
        } else {
            state.customerPhotoset.status = obj.status
        }
    },
    SET_LOADING_STATUS(state, status) {
        state.isLoading = status
    },
};

const actions = {
    async getCustomerPhotoset({ commit }, psId ){
        await axios.get(`/api/ps/${psId}`).then((response) => {
            commit('UPDATE_CUSTOMER_PHOTOSET', response.data)
        }).catch((error) => {
            if (error.response.status === 404) {
                throw new Error('404');
            } else if (error.response.status >= 500) {
                console.log("There is a server problem")
                throw new Error('500');
            }
        })
    },
    async getCustomerPhotosetPhotos({ commit }, psId){
        commit('SET_CUSTOMER_PHOTOSET_PHOTOS_LOADING_STATE', true)
        await axios.get(`/api/ps/${psId}/photos`).then((response) => {
            commit('UPDATE_CUSTOMER_PHOTOSET_PHOTOS', response.data)
            commit('SET_CUSTOMER_PHOTOSET_PHOTOS_LOADING_STATE', false)
        }).catch((error) => {
            // add some error logic
            commit('SET_CUSTOMER_PHOTOSET_PHOTOS_LOADING_STATE', false)
            if (error.response.status === 404) {
                console.log("It seems like there is a problem with the photoset")
            }
            
        })
    },
    addCartItem({ commit, getters}, photoId) {
        commit('ADD_REMOVE_CART_PENDING')
        axios.post(`/api/ps/${getters.customerPhotoset.id}/cart/photos/${photoId}`).then((response) => {
            if (response.data.status === "created") {
                commit('ADD_CART_ITEM', photoId)
                commit('ADD_REMOVE_CART_DONE')
            }
        }).catch((error) => {
            if (error.response.status === 403) {
                // toast 
                toast.warning(`You already selected ${getters.customerPhotoset.downloadablePhotos} photos\nGet in touch with your photographer ${getters.customerPhotoset.author} to get more photos`, {
                    toastClassName: "my-custom-warning-class"
                })
                console.log("Seems like the cart is full...")
                commit('ADD_REMOVE_CART_DONE')
                //vibrate for android phones
                navigator.vibrate(200)
            } else {
                console.log("It seems like there is a problem with adding this photo to the cart...")
            }
            
        })
    },
    removeCartItem({ commit, getters }, photoId) {
        commit('ADD_REMOVE_CART_PENDING')
        axios.delete(`/api/ps/${getters.customerPhotoset.id}/cart/photos/${photoId}`).then((response) => {
            if (response.status === 204) {
                commit('REMOVE_CART_ITEM', photoId)
                commit('ADD_REMOVE_CART_DONE')
            }
        }).catch((error) => {
            // do something 
            if (error.response.status === 403) {
                console.log("error deleting photo from cart as it is probably already deonwloaded")
            } else {
                console.log("error deleting photo from cart")
            }
            commit('ADD_REMOVE_CART_DONE')
            
        })
    },
    getAllCartItems({ commit }, psId) {
        axios.get(`/api/ps/${psId}/cart/photos`).then((response) => {
            if (response.status === 200) {
                commit('UPDATE_CART_ITEMS', response.data)
            } else {
                console.log('Status other than 200 received')
            }
        }).catch((error) => {
            console.log(error)
        })
    },
    setLCardOpenStatus({ commit }, status) {
        commit('SET_L_PHOTOCARD_OPEN', status)
    },
    setLPhotoCardActivePhoto({commit}, photo) {
        // receive photoId or event Photo - refactor 
        commit('SET_L_PHOTOCARD_ACTIVE_PHOTO', photo)
    },
    setToAll({ commit }) {
        commit('SET_TO_ALL')
        commit('SET_ACTIVE_PHOTOS_SOURCE_TO_PHOTOSET_PHOTOS')
    },
    setToDownloaded({ commit }) {
        commit('SET_TO_DOWNLOADED')
        commit('SET_ACTIVE_PHOTOS_SOURCE_TO_SORTED_PHOTOS')
    },
    setToSelected({ commit }) {
        commit('SET_TO_SELECTED')
        commit('SET_ACTIVE_PHOTOS_SOURCE_TO_SORTED_PHOTOS')
    },
    setToUnselected({ commit }) {
        commit('SET_TO_UNSELECTED')
        commit('SET_ACTIVE_PHOTOS_SOURCE_TO_SORTED_PHOTOS')
    },
    setToRetouched({ commit }) {
        commit('SET_TO_RETOUCHED')
        commit('SET_ACTIVE_PHOTOS_SOURCE_TO_SORTED_PHOTOS')
    },
    setToUnretouched({ commit }) {
        commit('SET_TO_UNRETOUCHED')
        commit('SET_ACTIVE_PHOTOS_SOURCE_TO_SORTED_PHOTOS')
    },
    setToSelectedRetoucheCandidates({ commit }) {
        commit('SET_TO_SELECTED_RETOUCHE_CANDIDATE')
        commit('SET_ACTIVE_PHOTOS_SOURCE_TO_SORTED_PHOTOS')
    },
    setToUnselectedRetoucheCandidates({ commit }) {
        commit('SET_TO_UNSELECTED_RETOUCHE_CANDIDATE')
        commit('SET_ACTIVE_PHOTOS_SOURCE_TO_SORTED_PHOTOS')
    },
    emptyCart({ commit }, psId) {
        axios.delete(`/api/ps/${psId}/cart/photos`).then((response) => {
            if (response.status === 204 ) {
                commit('EMPTY_CART')
            }
        }).catch((error) => {
            console.log(error)
        })
    },
    async downloadItem({ commit, getters}, photoId) {
        const link = document.createElement('a');
        link.id = `${photoId}`; //give it an ID!
        link.href = `/api/ps/${getters.customerPhotoset.id}/cart/photos/${photoId}/download`;
        link.click();
        commit('UPDATE_CART_ITEM_TO_DOWNLOADED', photoId)
    },
    async downloadAll({ commit, getters }) {
        let downloadTimer;
        let attempts = 15;

        commit('DOWNLOAD_LOADING', true)
        const cookieId = Date.now()
        const link = document.createElement('a');
        link.id = 'archive-download'; //give it an ID!
        link.href = `/api/ps/${getters.customerPhotoset.id}/cart/photos/download?q=${cookieId}`;
        link.click();
        downloadTimer = window.setInterval(() => {
            let token = getCookie( "fileDownloadToken" );
            if( (token == cookieId) || (attempts === 0) ) {
                commit('DOWNLOAD_LOADING', false)
                commit('UPDATE_ALL_CART_ITEM_TO_DOWNLOADED')
                window.clearInterval( downloadTimer );
                deleteCookie( "fileDownloadToken" );
            }
            attempts--;
        }, 1000 );
    },
    sendViewportWidth({commit}, width) {
        commit('SET_CARD_SECTION_GRID_LAYOUT', width)
    },
    nextPhoto({commit, getters}, index) {
        if (getters.activePhotoSource === 'photosetPhotos') {
            let photo = getters.customerPhotoset.photos[ index + 1]
            commit('SET_L_PHOTOCARD_ACTIVE_PHOTO', photo)
        } else {
            let photo = getters.sortedPhotos[ index + 1]
            commit('SET_L_PHOTOCARD_ACTIVE_PHOTO', photo)
        }
    },
    previousPhoto({commit, getters}, index) {
        if (getters.activePhotoSource === 'photosetPhotos') {
            let photo = getters.customerPhotoset.photos[ index - 1]
            commit('SET_L_PHOTOCARD_ACTIVE_PHOTO', photo)
        } else {
            let photo = getters.sortedPhotos[ index - 1]
            commit('SET_L_PHOTOCARD_ACTIVE_PHOTO', photo)
        }
    },
    addAllItemsToCart({commit}, psId) {
        axios.post(`/api/ps/${psId}/cart/photos/all`).then((response) => {
            if (response.status === 200) {
                commit('UPDATE_CART_ITEMS', response.data)
            } else {
                console.log('Status other than 200 received')
            }
        }).catch((error) => {
            console.log(error)
        })
    },
    addAllRetoucheReadyItemsToCart({commit}, psId) {
        axios.post(`/api/ps/${psId}/cart/photos/all?retouche-status=retouche-ready`).then((response) => {
            if (response.status === 200) {
                commit('UPDATE_CART_ITEMS', response.data)
            } else {
                console.log('Status other than 200 received')
            }
        }).catch((error) => {
            console.log(error)
        })
    },

    changePhotoRetoucheStatusToUnassigned({ commit, getters}, photoId) {
        commit('ADD_REMOVE_CART_PENDING')
        const bodyContent = {
            "retoucheStatus": "unassigned",
        }
        axios.patch(`/api/ps/${getters.customerPhotoset.id}/photos/${photoId}/retouche-status`, bodyContent).then((response) => {
           
            if (response.data.retoucheStatus === 'unassigned') {
                commit('UPDATE_PHOTO_RETOUCHE_STATUS', { 
                    photoId, 
                    bodyContent,
                } )
                commit('ADD_REMOVE_CART_DONE')
            }
        }).catch((error) => {
           
            console.log("Something wrong :" + error)
            commit('ADD_REMOVE_CART_DONE')
            
        })
    },
    changePhotoRetoucheStatusToRetoucheCandidate({ commit, getters}, photoId) {
        commit('ADD_REMOVE_CART_PENDING')
        const bodyContent = {
            "retoucheStatus": "retouche-candidate",
        }
        axios.patch(`/api/ps/${getters.customerPhotoset.id}/photos/${photoId}/retouche-status`, bodyContent).then((response) => {
            if (response.data.retoucheStatus === 'retouche-candidate') {
                commit('UPDATE_PHOTO_RETOUCHE_STATUS',  { 
                    photoId,
                    bodyContent,
                } )
                commit('ADD_REMOVE_CART_DONE')
            }
        }).catch((error) => {
            if (error.response.status === 403) {
                // toast 
                toast.warning(`You have already selected ${getters.customerPhotoset.retoucheablePhotos} photos for retouche`, {
                    toastClassName: "my-custom-warning-class"
                })
                commit('ADD_REMOVE_CART_DONE')
                //vibrate for android phones
                navigator.vibrate(200)
            } else {
                console.log("It seems like there is a problem with adding this photo to the cart...")
            }
            
        })
    },
    changeSendNowModalStatus({commit}, status) {
        commit('CHANGE_SEND_NOW_MODAL_STATUS', status)
    },
    setPhotosetStatusToReviewed({commit, getters}, obj) {
        commit('SET_LOADING_STATUS', true)
        axios.patch(`/api/ps/${getters.customerPhotoset.id}/status`, obj).then((response) => {
            commit('SET_PHOTOSET_STATUS_TO_REVIEWED', response.data)
        }).catch((error) => {
            toast.warning(`${error.response.data.message}`, {
                toastClassName: "my-custom-warning-class"
            })
            
        }).finally(() => {
            commit('SET_LOADING_STATUS', false)
            commit('CHANGE_SEND_NOW_MODAL_STATUS', false)
        })

    }
};

const getters = {
    customerPhotoset: state => state.customerPhotoset,
    getCustomerPhotosetPhotos: state => state.customerPhotoset?.photos,
    customerPhotosetPhotosLoading: state => state.customerPhotosetPhotosLoading,
    customerCart: state => state.customerCart,
    lPhotoCardOpen: state => state.lPhotoCardOpen,
    lPhotoCardActivePhoto: state => state.lPhotoCardActivePhoto,
    cartAddRemoveLoading: state => state.cartAddRemoveLoading,
    photoSelectedTags: state => state.photoSelectedTags,
    sortedPhotos: state => state.sortedPhotos,
    cartSectionGridLayout: state => state.cartSectionGridLayout,
    downloadLoading: state => state.downloadLoading,
    activePhotoSource: state => state.activePhotoSource,
    isSendNowModalActive: state => state.isSendNowModalActive,
    isLoading: state => state.isLoading
};

const customerModule = {
  state,
  mutations,
  actions,
  getters,
};

export default customerModule;
