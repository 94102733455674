<template>
  <button :disabled="customerCart.length === 0"
          class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center button  is-outlined has-text-primary is-small is-rounded is-size-7 has-text-weight-bold  pl-2"
          @click="downloadIndividually">
            <span class="material-icons-outlined is-size-7 pr-2" v-if="isIconEnabled">
                file_download
              </span>
    <span>
          {{ buttonLabel }}
        </span>
  </button>
</template>

<script>
import {mapGetters} from 'vuex'
import router from "@/router";

export default {
  name: "DownloadIndividuallyButton",
  props: {
    buttonLabel: {
      type: String,
      required: true,
      default: 'Download'
    },
    isIconEnabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      "customerPhotoset",
      "customerCart"
    ])
  },
  methods: {
    downloadIndividually() {
      router.push(`${this.customerPhotoset.id}/checkout`)
    }
  }

}
</script>

<style>

</style>