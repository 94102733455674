<template>
    <button class="button is-small is-rounded is-size-7 ml-3 p-3" >
        <span class="material-icons-outlined  favorite-heart has-background-white is-round-icon is-unclickable  is-unselectable has-tooltip-arrow" :class="favoriteClass" :data-tooltip="favoriteTootip">
            favorite
        </span>
    </button>
</template>

<script>
export default {
    name: "FavoritesButton",
    props: ['photo'],
    computed: {
        favoriteClass() {
            return this.photo.favorite ? 'has-text-danger' : 'has-text-grey-light'
        },
        favoriteTootip() {
            return this.photo.favorite ? 'Remove from Favorites' : 'Add to Favorites'
        }
    }
}
</script>

<style>

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(0.95);
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}

.favorite-heart {
  cursor: pointer; /* Hand pointer on hover */
  display: inline-block; /* Required for transform animations */
  transition: transform 0.2s; /* Smoothens the 'return to normal size' process */
}

.favorite-heart:active {
  animation: heartBeat 0.5s 1; /* Applies the heartbeat animation once upon active/click state */
}
</style>