<template>
  <div class="py-5">
      <photo-navigation-tabs-in-ready v-if="customerPhotoset.status === 'ready'"/>
      <photo-navigation-tabs-in-review v-if="customerPhotoset.status === 'review' || customerPhotoset.status === 'reviewed'" />
  </div>
</template>

<script>
import PhotoNavigationTabsInReady from "@/components/photoset/PhotoNavigationTabsInReady";
import PhotoNavigationTabsInReview from "@/components/photoset/PhotoNavigationTabsInReview";
import { mapGetters} from "vuex";

export default {
  name: "PhotoNavigationTabs",
  components: { PhotoNavigationTabsInReady, PhotoNavigationTabsInReview},
  computed: {
    ...mapGetters([
      "customerPhotoset"
    ]),
  }
}
</script>

<style scoped>

</style>