<template>
<div class="photoset-photos" >
    <photo-navigation-tabs />
    <div class="photos-section pt-4" v-if="photoSelectedTags.all" v-show="customerPhotosetPhotosLoading">
            <div v-for="i in 16" :key = "i">
                <m-photo-card-skeleton />
            </div>
    </div>

    <div  class="photos-section pt-4" v-if="displayedPhotos.length > 0" v-show="!customerPhotosetPhotosLoading">
        <transition-group name="fade" >   
            <div v-for="photosetPhoto in displayedPhotos" :key = "photosetPhoto.key">
                <m-photo-card :photo="photosetPhoto" /> 
            </div>
        </transition-group>
            <div v-if="customerPhotoset.status === 'ready'">
                <l-photo-card v-if="lPhotoCardActivePhoto"  :photos="displayedPhotos"/>
            </div>
            <div  v-if="customerPhotoset.status === 'review' || customerPhotoset.status === 'reviewed'" >
                <l-photo-card-review v-if="lPhotoCardActivePhoto"  :photos="displayedPhotos"/>
            </div>    
    </div>
	<section class="section" v-if="displayedPhotos.length === 0">
            <div class="container">
                <div class="columns is-centered">
                <div class="column is-half">
                    <nothing-here />
                    <div class="has-text-weight-semibold grey-500 is-size-4 center">
                        Nothing here
                    </div>
                </div>
                </div>
            </div>
    </section>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import MPhotoCardSkeleton from './MPhotoCardSkeleton.vue'
import MPhotoCard from './MPhotoCard.vue'
import LPhotoCard from './LPhotoCard.vue'
import LPhotoCardReview from './LPhotoCardReview.vue'
import NothingHere from '../empty-state/NothingHere.vue'
import PhotoNavigationTabs from "@/components/photoset/PhotoNavigationTabs";
export default {
    name: "PhotoSection",
    props: ['photoset'],
    components: { MPhotoCard, LPhotoCard, NothingHere, MPhotoCardSkeleton,  LPhotoCardReview, PhotoNavigationTabs},
    computed: {
        ...mapGetters([
            'lPhotoCardActivePhoto',
            'photoSelectedTags',
            'sortedPhotos',
            "customerPhotosetPhotosLoading",
            "customerPhotoset"
        ]),

        displayedPhotos() {
            return this.photoSelectedTags.all
                ? this.customerPhotoset?.photos?.map(photo => ({ ...photo, key: `c_${photo.id}` })) ?? []
                : this.sortedPhotos?.map(photo => ({ ...photo, key: `s_${photo.id}` })) ?? []
        },
    },
    beforeUnmount() {
        this.$store.dispatch('setLCardOpenStatus', false)
        this.$store.dispatch('setLPhotoCardActivePhoto', null)
    },

}
</script>

<style>

.is-tag-selected:hover {
    -webkit-box-shadow: inset 0px 0px 0px 1px;
    -moz-box-shadow: inset 0px 0px 0px 10px;
    box-shadow: inset 0px 0px 0px 1px;
    transform: scale(1.01) translateY(-2px);
}

.is-tag-selected:active {
    -webkit-box-shadow: inset 0px 0px 0px 1px;
    -moz-box-shadow: inset 0px 0px 0px 10px;
    box-shadow: inset 0px 0px 0px 1px;
    transform: scale(0.99) translateY(-1px);
}

.center {
  text-align: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s linear;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(20px);
}
</style>