<template>
  <div class="container is-max-desktop">
    <div id="checkout-details-controls">
      <div class="pt-5 is-flex is-flex-direction-row is-justify-content-space-between">
        <button class="button is-primary is-light is-small is-rounded is-size-8 has-text-weight-bold pl-2" @click="$router.go(-1)">
          <span class="material-icons-outlined is-size-6 pr-1">
            navigate_before
          </span>
          Back
        </button>
        <download-button buttonLabel="Download All" :is-icon-enabled="true"/>
        <!-- <button class="button is-primary is-outlined is-small is-rounded is-size-8 has-text-weight-bold is-pulled-right pl-2 ml-2">
          <span class="material-icons-outlined is-size-7 pr-1">
            send
          </span>
          Send to Email
        </button> -->
        
      </div>
    </div>
    <cart-section />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CartSection from '../components/cart/CartSection.vue'
import DownloadButton from '../components/cart/DownloadButton.vue'
export default {
    name: "CheckoutView",
    props: ['psId'],
    components: { CartSection, DownloadButton },
    created() {
        this.$store.dispatch('getCustomerPhotoset', this.psId).then(()=> {
            this.$store.dispatch('getAllCartItems', this.psId)
            this.$store.dispatch('sendViewportWidth', window.innerWidth )
            document.title = `${this.customerPhotoset.name} by ${this.customerPhotoset.author}`
        }).catch((err) => {
            if (err.message == 404) {
                document.title = "TerraPics - Photoset Not Found"
                this.$router.push({ name: 'NotFound' })
                console.log("Error occured: " + err.message)
            } else if (err.message == 500) {
                document.title = "TerraPics - Server Issues"
                this.$router.push({ name: 'InternalServerError' })
                console.log("Error occured: " + err.message)
            }
            
        })

    },
    computed: {
        ...mapGetters([
            "customerPhotoset",
            "customerCart",
            "downloadLoading"
        ])
    },
    methods: {
      downloadAll() {
        this.$store.dispatch('downloadAll')
      }
    }
}
</script>


<style>


</style>