<template>
  <svg width="100%" height="100%" viewBox="0 0 137 129" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M122.483 55.8155C122.483 70.0155 116.683 82.8155 107.383 92.0155C98.2833 101.116 85.6833 106.616 71.7833 106.616C57.9833 106.616 45.3833 101.016 36.1833 92.0155C26.8833 82.8155 21.0833 70.0155 21.0833 55.8155C21.0833 27.7155 43.7833 5.0155 71.7833 5.0155C99.7833 5.0155 122.483 27.8155 122.483 55.8155Z" fill="#EAEEF9"/>
<path d="M118.083 22.5155C120.348 22.5155 122.183 20.6799 122.183 18.4155C122.183 16.1511 120.348 14.3155 118.083 14.3155C115.819 14.3155 113.983 16.1511 113.983 18.4155C113.983 20.6799 115.819 22.5155 118.083 22.5155Z" fill="#EAEEF9"/>
<path d="M124.083 6.51553C125.63 6.51553 126.883 5.26192 126.883 3.71553C126.883 2.16913 125.63 0.915527 124.083 0.915527C122.537 0.915527 121.283 2.16913 121.283 3.71553C121.283 5.26192 122.537 6.51553 124.083 6.51553Z" fill="#EAEEF9"/>
<path d="M22.6833 22.4155C24.2297 22.4155 25.4833 21.1619 25.4833 19.6155C25.4833 18.0691 24.2297 16.8155 22.6833 16.8155C21.1369 16.8155 19.8833 18.0691 19.8833 19.6155C19.8833 21.1619 21.1369 22.4155 22.6833 22.4155Z" fill="#EAEEF9"/>
<path d="M5.5833 76.4155C8.45518 76.4155 10.7833 74.0874 10.7833 71.2155C10.7833 68.3436 8.45518 66.0155 5.5833 66.0155C2.71142 66.0155 0.383301 68.3436 0.383301 71.2155C0.383301 74.0874 2.71142 76.4155 5.5833 76.4155Z" fill="#EAEEF9"/>
<g filter="url(#filter0_d)">
<path d="M112.632 32.2093C111.264 30.2957 109.212 30.4658 108.485 32.8047C106.861 37.9926 101.773 39.9062 98.6525 40.374C96.857 27.0639 85.3997 16.8156 71.5483 16.8156C57.7397 16.8156 46.3252 27.0214 44.4869 40.2464C41.3233 39.6511 36.7062 37.6949 35.1671 32.7621C34.4404 30.4233 32.3883 30.2957 31.0203 32.1668C28.2415 35.994 29.3957 40.8843 31.1058 44.8815C33.6281 50.9625 38.5017 55.3 44.2304 58.2341C44.1021 65.4207 43.5891 71.2891 41.5798 78.8584C40.8958 81.4524 40.2973 83.5786 39.7415 84.9819C39.3567 85.9599 39.1002 86.938 39.6988 87.916C40.7248 89.5745 42.7341 90.0848 44.5724 90.3825C46.2824 90.6801 47.736 90.8077 48.933 90.8502C51.8401 90.9353 53.1226 90.51 53.1226 90.51C53.1226 90.51 53.3364 90.8502 53.8921 91.318C55.4739 92.7213 59.8773 95.4428 69.7955 95.0601C82.1506 94.5924 86.896 93.4017 89.0763 91.5306C89.2473 91.3605 89.4183 91.2329 89.5466 91.0628C90.9146 91.0628 92.1544 91.1054 93.2659 91.1054C97.1562 91.1479 99.9351 91.2329 102.158 90.425C103.911 89.7871 105.022 87.746 103.911 85.5772C100.576 79.0285 98.8663 71.7994 98.8663 64.4427V58.4893C104.809 55.5551 109.939 51.1751 112.547 44.8815C114.257 40.8843 115.411 35.994 112.632 32.2093Z" fill="url(#paint0_linear)"/>
</g>
<path d="M93.3081 91.0203C92.1539 91.0203 90.9141 90.9777 89.5888 90.9777C89.4605 91.1478 89.2895 91.3179 89.1185 91.4455C87.8788 85.9599 86.81 80.3467 86.5535 73.4578C88.3063 79.3686 90.5293 86.3426 93.3081 91.0203Z" fill="#DFE0E2"/>
<path d="M53.8916 91.2754C53.3359 90.8076 53.1221 90.4674 53.1221 90.4674C53.1221 90.4674 51.8396 90.9352 48.9325 90.8076C51.3693 86.3426 53.5496 77.9653 53.2931 72.7773C53.6779 81.9201 53.8061 87.6608 53.8916 91.2754Z" fill="#DFE0E2"/>
<path d="M61.4157 47.7731C63.4463 47.7731 65.0923 46.1358 65.0923 44.116C65.0923 42.0963 63.4463 40.4589 61.4157 40.4589C59.3852 40.4589 57.7391 42.0963 57.7391 44.116C57.7391 46.1358 59.3852 47.7731 61.4157 47.7731Z" fill="#9CA4B5"/>
<path d="M79.7988 49.6868C82.8918 49.6868 85.3992 47.1927 85.3992 44.1161C85.3992 41.0395 82.8918 38.5454 79.7988 38.5454C76.7057 38.5454 74.1984 41.0395 74.1984 44.1161C74.1984 47.1927 76.7057 49.6868 79.7988 49.6868Z" fill="#9CA4B5"/>
<path d="M81.2523 54.8321C81.2523 56.2354 76.3786 57.3835 70.3935 57.3835C64.3656 57.3835 59.4919 56.2354 59.4919 54.8321H81.2523Z" fill="#9CA4B5"/>
<path d="M72.9158 67.0791C70.137 67.0791 67.9139 64.8253 67.9139 62.1037V56.1503H77.9605V62.1037C77.9605 64.8253 75.6946 67.0791 72.9158 67.0791Z" fill="#9CA4B5"/>
<path opacity="0.2" d="M71.5478 106.329C87.6268 106.329 100.661 103.816 100.661 100.716C100.661 97.6157 87.6268 95.1025 71.5478 95.1025C55.4689 95.1025 42.4343 97.6157 42.4343 100.716C42.4343 103.816 55.4689 106.329 71.5478 106.329Z" fill="#A0A9BA"/>
<defs>
<filter id="filter0_d" x="7.34863" y="5.81555" width="128.955" height="122.281" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="11"/>
<feGaussianBlur stdDeviation="11"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<linearGradient id="paint0_linear" x1="71.7985" y1="15.0048" x2="71.7985" y2="95.9405" gradientUnits="userSpaceOnUse">
<stop stop-color="#FDFEFF"/>
<stop offset="0.9964" stop-color="#ECF0F5"/>
</linearGradient>
</defs>
</svg>

</template>

<script>
export default {
    name: "NothingHere",

}
</script>

<style>

</style>