<template>
<div class="photoset-photos" id="cart-photoset-photos">
    <div class="pt-3 pb-2">
        <div class="is-flex is-flex-direction-row is-justify-content-space-between">
            <span class="has-text-weight-bold grey-500">
                Cart: {{customerCart.length}} photos
            </span>
            <!-- <span>
                <span class="material-icons-outlined is-size-3 blue-500 is-clickable">
                    grid_view
                </span>
                <span class="material-icons is-size-3 blue-500 is-clickable">
                    reorder
                </span>
            </span> -->
        </div>
    </div>
    <div class="photos-section pt-4">  
            <div v-for="cartItem in customerCart" :key = "cartItem.id">
                <photo-card :photo="cartItem" /> 
            </div>
    </div>
</div>
</template>

<script>
import PhotoCard from './PhotoCard.vue'
import { mapGetters } from 'vuex'
export default {
    name: "CartSection",
    components: { PhotoCard },
    computed: {
        ...mapGetters(['customerCart']),
    }

}
</script>

<style>
#cart-photoset-photos {
    margin-top: 85px;
}

</style>