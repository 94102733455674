<template>
    <div class="container is-max-desktop has-background-white">
      <photoset-detail-section  v-if="customerPhotoset"/>
      <photoset-life-cycle v-if="getCustomerPhotosetPhotos" :accordion-class="accordionClass"/>
      <photo-section :photoset="customerPhotoset" v-if="customerPhotoset"/>
    </div>
  <send-now-modal />

</template>

<script>
import { mapGetters } from 'vuex'
import PhotoSection from '../components/photoset/PhotoSection.vue'
import PhotosetDetailSection from '../components/photoset/PhotosetDetailSection.vue'
import PhotosetLifeCycle from '../components/photoset/photoset-lifecycle/PhotosetLifeCycle.vue'
import SendNowModal from '../components/modals/SendNowModal.vue'

export default {
  components: { PhotosetDetailSection, PhotoSection, PhotosetLifeCycle, SendNowModal },
  name: "MainView",
  props: ['psId'],
  data() {
    return {
      accordionClass: 'is-accordion-horizontal'
    }
  },
  created() {
    this.$store.dispatch('getCustomerPhotoset', this.psId).then(()=> {
      this.$store.dispatch('getCustomerPhotosetPhotos', this.psId)
      document.title = `${this.customerPhotoset.name} by ${this.customerPhotoset.author}`
    }).catch((err) => {
      if (err.message == 404) {
        document.title = "TerraPics - Photoset Not Found"
        this.$router.push({ name: 'NotFound' })
      } else if (err.message == 500) {
        document.title = "TerraPics - Server Issues"
        this.$router.push({ name: 'InternalServerError' })
      }

    })

  },
  computed: {
    ...mapGetters([
      "customerPhotoset",
      "toasts",
      'getCustomerPhotosetPhotos'
    ])
  }

}
</script>

<style>

@media screen and (min-width: 1261px) and (max-width: 1560px) {
  .photoset-lifecycle-container {
    flex: none;
    width: 300px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1260px) {
  .empty-container {
    display: none;
  }
  .photoset-lifecycle-container {
    flex: none;
    width: 300px;
  }
  .main-container {
    width: calc(100% - 300px);
  }
}

@media screen and (max-width: 1023px) {
  .main-container {
    width: 100%;
    max-width: 1023px;
    min-width: 300px;
  }
  .photoset-lifecycle-container {
    display: none;
  }
}

/*#photoset-life-cycle-wrapper {*/
/*  position: fixed;*/
/*  top: 20px; !* Adjust the value as needed *!*/
/*  left: 0px; !* Adjust the value as needed *!*/
/*  z-index: 1000; !* Ensures this element stays on top of others *!*/
/*}*/
</style>