<template>
  <button  class="button has-background-gradient-primary is-small is-rounded has-text-white is-size-7 has-text-weight-bold  pl-2" @click="openSendNowModal" :disabled = "!enabled">
        <span class="material-icons-outlined is-size-7 pr-2">
            send
        </span>
        <span>
            Send for Retouching
        </span>
        
    </button>
</template>

<script>
export default {
    name: "SendNowButton",
    props: {
        enabled: {
            type: Boolean,
            required: false
        }
    },
    methods: {
        openSendNowModal() {
            this.$store.dispatch('changeSendNowModalStatus', true)
        }
    }

}
</script>

<style lang="scss">

</style>