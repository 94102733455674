<template>
  <nav class="level">
    <!-- Left side -->
    <div class="level-left">
      <div class="level-item" v-if="customerPhotoset.isFavoritesEnabled">

        <p class="is-clickable status-tag has-text-weight-bold tag is-outlined is-unselectable is-rounded is-relative pl-2 is-size-6">
            <span
                class="material-icons-outlined is-size-7 has-text-weight-bold icons has-text-danger is-unselectable pr-1"> favorite </span>
          Favorites
        </p>

      </div>
    </div>


    <div class="level-right" v-if="customerPhotoset.status === 'review' || customerPhotoset.status === 'reviewed'">
      <div class="level-item pl-2" @click="handleSorting">
        <p :class="[{'is-light is-tag-selected': !photoSelectedTags.all}, 'is-clickable status-tag has-text-weight-bold tag is-success is-unselectable is-rounded is-relative pl-2 is-size-6']"
           id="sort-all">
            <span title="Badge top left" :class="[{'is-light': !photoSelectedTags.all}, 'badge is-top-right']">
              {{ customerPhotoset.photoCount }}
            </span>
          All
        </p>
      </div>
      <div class="level-item pl-2" @click="handleSorting">
        <p :class="[{'is-light is-tag-selected': !photoSelectedTags.selected}, 'is-clickable status-tag has-text-weight-bold tag is-success is-unselectable is-rounded is-relative pl-2 is-size-6']"
           id="sort-selected">
            <span title="Badge top left"
                  :class="[{'is-light': !photoSelectedTags.selected}, 'badge is-top-right']">{{ selected }}</span>
          Selected
        </p>
      </div>
      <div class="level-item pl-2" @click="handleSorting">
        <p :class="[{'is-light is-tag-selected': !photoSelectedTags.unselected}, 'is-clickable status-tag has-text-weight-bold tag is-success is-unselectable is-rounded is-relative pl-2 is-size-6']"
           id="sort-unselected">
            <span title="Badge top left"
                  :class="[{'is-light': !photoSelectedTags.unselected}, 'badge is-top-right']">{{ unselected }}</span>
          Unselected
        </p>
      </div>
    </div>

  </nav>

</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "PhotoNavigationTabsInReview",
  computed: {
    ...mapGetters([
      'photoSelectedTags',
      "customerPhotoset",
    ]),
    selected() { // this one is used in PhotosetLifeCycleDetailsIte DRY it and MIXIN
      return this.customerPhotoset.photos?.reduce((count, item) => {
        return count + (item.retoucheStatus === 'retouche-candidate' ? 1 : 0)
      }, 0)
    },
    unselected() {
      return this.customerPhotoset.photoCount - this.selected
    }
  },
  methods: {
    handleSorting(event) {
      switch (event.target.id) {
        case 'sort-all' :
          this.$store.dispatch('setToAll')
          break
        case 'sort-selected':
          this.$store.dispatch('setToSelectedRetoucheCandidates')
          break
        case 'sort-unselected':
          this.$store.dispatch('setToUnselectedRetoucheCandidates')
          break
      }
    }
  }
}
</script>

<style>

</style>