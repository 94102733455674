<template>

  <ul v-if="currentStep === 'downloadedDownload' || currentStep === 'downloadedRetouch'">
    <li class="is-size-7">
      <div class="is-line-height-15rem">
        {{ downloadedPrompt }}
      </div>
      <div class="mt-2">
        <download-button buttonLabel="Download All as Zip" style="width: 100%;"/>
        <download-individually-button class="mt-1" button-label="Download One by One" style="width: 100%;"/>
      </div>
    </li>
  </ul>

  <ul v-else-if="currentStep === 'readyDownload'">
    <li class="is-size-7">
      <div class="is-line-height-15rem">
        {{ readyDownloadPrompt }}
      </div>

      <div class="mt-1" v-if="isAllPhotosDownloadable && !isCartFull">
        <add-all-items-to-cart-button style="width: 100%;"/>
      </div>
      <div class="mt-2" v-else-if="isCartFull">
        <download-button buttonLabel="Download All as Zip" style="width: 100%;"/>
        <download-individually-button class="mt-1" button-label="Download One by One" style="width: 100%;"/>
      </div>
    </li>
  </ul>

  <ul v-else-if="currentStep === 'readyRetouch' || currentStep === 'readyDownload'" class="is-size-7">

    <div v-if="isRetouchablePhotoset" class="mb-3">
      <div v-if="isAllRetoucheReadySelected" class="is-line-height-15rem">
                  <span>
                    <strong>{{ retouchedPhotosTotal }}</strong> retouched photos selected
                  </span>
      </div>
      <div v-if="!isAllRetoucheReadySelected">
        <div v-if="retoucheReadyPhotosInCart >= 0">

          <div class="is-line-height-15rem">
            <strong>{{ retoucheReadyPhotosInCart }}</strong> of {{ retouchedPhotosTotal }} retouched photos selected
          </div>
          <div>
            <cart-progress-bar :progress="retoucheReadyPhotosInCart" :max="retouchedPhotosTotal" style="width: 100%;"
                               class="mt-3" v-show="retoucheReadyPhotosInCart > 0"/>
          </div>

        </div>

        <div v-if="!isAllPhotosDownloadable">
          <add-all-retouche-ready-items-to-cart style="width: 100%;" class="mt-1"/>
        </div>
      </div>
    </div>

    <div v-if="isDownlodablePhotoset">
      <div v-if="isAllDownloadableSelected">
          <span>
            <strong>{{ downloadableUnretouchedPhotosTotal }}</strong> {{ unretouchedPhotosLabel }}photos selected
          </span>
      </div>
      <div v-if="!isAllDownloadableSelected">
        <div v-if="downloadableUnretouchedPhotosInCart >= 0">
          <div>
            <strong>{{ downloadableUnretouchedPhotosInCart }}</strong> of {{ downloadableUnretouchedPhotosTotal }}
            {{ unretouchedPhotosLabel }}photos selected
          </div>
          <div>
            <cart-progress-bar :progress="downloadableUnretouchedPhotosInCart" :max="downloadableUnretouchedPhotosTotal"
                               style="width: 100%;" class="mt-3" v-show="downloadableUnretouchedPhotosInCart > 0"/>
          </div>
        </div>

      </div>

    </div>

    <div v-if="isAllPhotosDownloadable && !isCartFull">
      <add-all-items-to-cart-button style="width: 100%;" class="mt-2"/>
    </div>

    <div v-if="isCartFull" class="mt-2">
      <download-button buttonLabel="Download All as Zip" style="width: 100%;" class="mb-1"/>
      <download-individually-button button-label="Download One by One" style="width: 100%;"/>
    </div>

  </ul>

  <ul v-else-if="currentStep === 'review'">
    <li class="is-size-7">
      <div class="mb-2 is-line-height-15rem">
        {{ reviewPrompt }}
      </div>

      <div class="mt-1">
        <send-now-button style="width: 100%;"
                         :enabled="this.selectedRetoucheCandidates === this.customerPhotoset?.retoucheablePhotos"/>
      </div>
    </li>
  </ul>

  <ul v-else-if="currentStep === 'reviewed'">
    <li class="is-size-7">
      <div>
        {{ reviewedPrompt }}

      </div>

      <div class="my-1">
        <beach-lounger :width="'100%'"/>
      </div>
      <span v-if="customerPhotoset?.customerEmail" v-html="promtCustomerEmail"></span>
    </li>
  </ul>
</template>

<script>
import CartProgressBar from "./CartProgressBar.vue"
import {mapGetters} from "vuex"
import DownloadButton from '../../cart/DownloadButton.vue'
import AddAllItemsToCartButton from './AddAllItemsToCartButton.vue'
import AddAllRetoucheReadyItemsToCart from './AddAllRetoucheReadyItemsToCart.vue'
import SendNowButton from './SendNowButton.vue'
import BeachLounger from '../../empty-state/BeachLounger.vue'
import PhotoCounterMixIn from "@/mixins/PhotoCounterMixIn";
import DownloadIndividuallyButton from "@/components/cart/DownloadIndividuallyButton";

export default {
  name: "PhotosetLifeCycleDetailsItems",
  mixins: [PhotoCounterMixIn],
  props: {
    currentStep: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      downloadedPrompt: "All done! You can always download the photos you picked until access expires",
    }
  },
  components: {
    DownloadButton,
    AddAllItemsToCartButton,
    SendNowButton,
    BeachLounger,
    AddAllRetoucheReadyItemsToCart,
    CartProgressBar,
    DownloadIndividuallyButton
  },
  computed: {
    ...mapGetters([
      'customerPhotoset',
      'customerCart'
    ]),
    isAllDownloadableSelected() {
      return this.downloadableUnretouchedPhotosTotal === this.downloadableUnretouchedPhotosInCart
    },
    downloadablePhotosInCart() {
      return this.customerCart?.reduce((count, item) => {
        return count + (item.retoucheStatus != 'retouche-ready' ? 1 : 0)
      }, 0)

    },
    unretouchedPhotosLabel() {
      return this.customerPhotoset?.type === 'download' ? '' : 'unretouched ';
    },
    isRetouchablePhotoset() {
      return this.customerPhotoset?.photos.some(e => e.retoucheStatus === 'retouche-ready')
    },
    isDownlodablePhotoset() {
      return this.customerPhotoset?.downloadAll || this.customerPhotoset?.downloadablePhotos > 0
    },
    promtCustomerEmail() {
      return `We'll email you at <strong>${this.customerPhotoset?.customerEmail}</strong> when your photos are ready</strong>`
    },
    isAllPhotosDownloadable() {
      return (this.customerPhotoset?.downloadAll || this.customerPhotoset?.downloadablePhotos >= this.customerPhotoset?.photoCount)
    },
    isAllRetoucheReadySelected() {
      return (this.retouchedPhotosTotal === this.retoucheReadyCart)
    },
    retoucheReadyCart() {
      return this.customerCart?.reduce((count, item) => {
        return count + (item.retoucheStatus === 'retouche-ready' ? 1 : 0)
      }, 0)
    },
    downloadable() {
      return this.customerPhotoset?.downloadAll ? this.customerPhotoset.photoCount :
          ((this.customerPhotoset.downloadablePhotos >= this.customerPhotoset.photoCount) ? this.customerPhotoset.photoCount : this.customerPhotoset.downloadablePhotos)
    },
    selectedRetoucheCandidates() { // this one is used in PhotosetLifeCycle DRY it
      return this.customerPhotoset.photos?.reduce((count, item) => {
        return count + (item.retoucheStatus === 'retouche-candidate' ? 1 : 0)
      }, 0)
    },
    availablePhotos() {
      return this.downloadable - this.customerCart.length + this.retoucheReadyPhotosInCart
    },
    readyDownloadPrompt() {
      const retochedReadyPhotos = this.customerPhotoset.photos?.reduce((count, item) => {
        return count + (item.retoucheStatus === 'retouche-ready' ? 1 : 0)
      }, 0)
      const baseDownloadText = `You can select and download ${this.downloadable} of ${this.customerPhotoset.photoCount - retochedReadyPhotos} ${this.unretouchedPhotosLabel}photos`;

      if (this.isCartEmpty) {
        if (this.isAllPhotosDownloadable) {
          return `${baseDownloadText}. Click the button below to add all photos to cart`;
        }
        return baseDownloadText;
      }

      if (this.isCartFull) {
        return `All ${this.downloadable} photos selected!\nClick the button below to download them all in a zip archive`;
      }

      if (!this.isCartFull) {
        return `You still have ${this.availablePhotos} of ${this.downloadable} ${this.unretouchedPhotosLabel}photos to select`;
      }

      return `Oops...something went wrong and I am confused`;
    },
    reviewPrompt() {
      if (this.selectedRetoucheCandidates === 0) {
        return `Choose up to ${this.customerPhotoset.retoucheablePhotos} for retouching.\nOnce you're done, click the button below to proceed`
      } else if (this.selectedRetoucheCandidates > 0 && this.selectedRetoucheCandidates < this.customerPhotoset?.retoucheablePhotos) {
        return `Nice progress! You've chosen ${this.selectedRetoucheCandidates} so far.\nYou can pick ${this.customerPhotoset?.retoucheablePhotos - this.selectedRetoucheCandidates} more`
      } else if (this.selectedRetoucheCandidates === this.customerPhotoset?.retoucheablePhotos) {
        return `All set! You've selected ${this.customerPhotoset?.retoucheablePhotos} photos!\nClick the button below to finalize and send them to ${this.customerPhotoset.author}`
      } else {
        return `Oops...something went wrong and I am confused`
      }
    },
    reviewedPrompt() {
      return `Now it's ${this.customerPhotoset?.author.split(' ')[0]}'s turn.\nYou can relax while your photos are being retouched`
    },
    readyRetouchePrompt() {
      const hasRetouchedPhotos = this.retoucheReadyTotal > 0;
      const hasNoPhotosInCart = this.retoucheReadyCart === 0;
      const hasSomePhotosInCart = this.retoucheReadyCart > 0 && this.retoucheReadyCart < this.retoucheReadyTotal;

      if (hasRetouchedPhotos) {
        if (hasNoPhotosInCart) {
          return `You have ${this.retoucheReadyTotal} retouched photos.\nAdd them all to the Cart by clicking the button below`;
        }

        if (hasSomePhotosInCart) {
          const photosLeft = this.retoucheReadyTotal - this.retoucheReadyCart;
          return `You still have ${photosLeft} retouched photos\nAdd them all to the Cart by clicking the button below`;
        }
      }

      return `Oops...something went wrong and I am confused`;
    }


  }
}
</script>

<style>

.is-highlighted-item {
  background-color: white;
  border-radius: 9px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
}

.pop-enter-active,
.pop-leave-active {
  transition: all 0.4s linear;
}

.pop-enter-from,
.pop-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
.is-line-height-15rem {
  line-height: 1.5rem;
}
</style>