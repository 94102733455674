<template>
    <li class="accordion__item">
      <div class="accordion__trigger">
        <slot name="accordion-trigger"></slot>
      </div>
  
      <transition 
        name="accordion"
        @enter="start"
        @after-enter="end"
        @before-leave="start"
        @after-leave="end">

        <!-- eslint-disable vue/require-toggle-inside-transition -->
        <div class="accordion__content">
          <ul>
            <slot name="accordion-content"></slot>
          </ul>
        </div>
      </transition>
    </li>
  </template>
  
  
  <script>
  export default {
    name: "AppAccordionItem",
    inject: ["Accordion"],
    data() {
      return {
        index: null
      };
    },
    methods: {
      // open() {
      //   this.visible ? this.Accordion.active = null : this.Accordion.active = this.index
      // },
      start(el) {
        el.style.height = el.scrollHeight + "px";
      },
      end(el) {
        el.style.height = "";
      }
    },
    created() {
      this.index = this.Accordion.count++;
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .accordion__item {
    cursor: pointer;
    //padding: 10px 0px 10px 0px;
    position: relative;
  }
  
  .accordion__trigger {
    display: flex;
    justify-content: space-between;
  }
  
  .accordion-enter-active,
  .accordion-leave-active {
    will-change: height, opacity;
    transition: height 0.3s ease, opacity 0.3s ease;
    overflow: hidden;
  }
  
  .accordion-enter,
  .accordion-leave-to {
    height: 0 !important;
    opacity: 0;
  }


  </style>
  