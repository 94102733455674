<template>
  <div class="photo-card-size-m is-clickable" >

    <figure v-lazyload class="photo-card-size-m-image is-unselectable">
      <img :data-src="photo.src.l['0']" class="lazy"  @click="setLCardOpenStatusTrue()" @load="imageLoaded = true" v-show="imageLoaded" :alt="photo.name" :class="{'retouche-ready-shadow ': retoucheReady}">
      <Skeletor height="300" class="is-border-radius-9 mb-2" v-if="!imageLoaded"/>
        <span v-show="retoucheReady" class="material-icons-outlined top-left-icon is-size-6 p-1 has-background-gradient-primary has-text-white is-round-icon is-unclickable is-unselectable has-solid-border has-tooltip-arrow has-tooltip-right bottomless" data-tooltip="Retouched">
          auto_fix_high
        </span>
        <span v-show="retoucheCandidate" class="material-icons-outlined top-left-icon is-size-6 p-1 has-background-white has-text-primary is-round-icon is-unclickable is-unselectable has-solid-border has-tooltip-arrow has-tooltip-right bottomless" data-tooltip="Selected for retouche">
          done_all
        </span>
        <span v-show="isFavorite" class="material-icons-outlined top-right-icon is-size-7 p-1 has-background-white has-text-primary is-round-icon is-unclickable has-text-danger is-unselectable has-solid-border has-tooltip-arrow has-tooltip-right bottomless" data-tooltip="Added to Favorites">
          favorite
        </span>
        <span v-show="photo.downloaded" class="material-icons-outlined top-right-icon is-size-7 p-1 has-background-success has-text-white is-round-icon is-unclickable is-unselectable has-solid-border has-tooltip-arrow has-tooltip-left bottomless" data-tooltip="Downloaded">
          arrow_downward
        </span>
        <span v-show="cartItem" class="material-icons-outlined top-right-icon is-size-7 p-1 has-background-white has-text-success is-round-icon is-unclickable  is-unselectable has-solid-border has-tooltip-arrow has-tooltip-left bottomless" data-tooltip="Selected">
          done
        </span>
    </figure>
    <div class="photo-card-size-m-filename has-text-centered has-text-weight-bold grey-600 is-size-7 is-text-overflow-ellipsis" >
        {{ photo.name }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { fileSize } from '../../utils/index'
import { Skeletor } from "vue-skeletor";
export default {
  name: "MPhotoCard",
  props: ['photo'],
  components: {
    Skeletor
  },
  methods: {
      setLCardOpenStatusTrue() {
          this.$store.dispatch('setLCardOpenStatus', true)
          this.$store.dispatch('setLPhotoCardActivePhoto', this.photo)
      }
  },
  computed: {
    ...mapGetters(['customerCart']),
    photoSize() {
      return fileSize(this.photo.size, 2)
    },
    cartItem() {
      return this.customerCart.some(item => item.id === this.photo.id && item.downloaded === false)
    },
    retoucheReady() {
      return this.photo.retoucheStatus === 'retouche-ready'
    },
    retoucheCandidate() {
      return this.photo.retoucheStatus === 'retouche-candidate'
    },
    isFavorite() {
      return this.photo.favorite
    }
  },
  data() {
      return {
        imageLoaded: false
      }
  }
  
}
</script>

<style>


.corner-badge {
  font-size: 6px !important;
}

.retouche-ready-shadow {
  box-shadow: 0 0em 17px 5px hsl(229deg, 53%, 53%), 0 0 0px 0px rgba(10, 10, 10, 0.02);
}

</style>