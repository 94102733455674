<template>
<div id="photoset-details" :style="backgroundPhoto" v-if="customerPhotoset.photos" class="is-flex is-flex-direction-column is-justify-content-space-between pl-4 pr-4 pb-2">
  <div>
    <div class="pt-3 is-flex is-flex-direction-row	is-justify-content-space-between">
      <div class="is-size-3 has-text-weight-bold has-text-white" v-if="customerPhotoset.name">{{ customerPhotoset.name}}</div>
      <div>
        <router-link :to="'/' + customerPhotoset.id + '/checkout'">
          <button class="button is-primary is-small is-rounded is-size-8 has-text-weight-bold" :disabled = "customerCart.length === 0"  v-if="customerPhotoset.status === 'ready'">
          <span class="material-icons-outlined is-size-6 pr-1">
            shopping_cart
          </span>
            <span class="badge is-success is-top-left" v-if="customerCart.length > 0">{{ customerCart.length }}</span>
            Cart
          </button>
        </router-link>
      </div>
    </div>
    <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
      <div >
        <div class="is-size-6 has-text-weight-bold grey-200">by {{ customerPhotoset.author}}</div>
      </div>
      <div class="is-flex is-flex-direction-row-reverse "  v-if="customerPhotoset.status === 'ready'">
        <div  v-if="allowDownloadAll" @click.prevent="addAllItemsToCart">
          <div class="ml-4">
            <a class="is-text is-small is-rounded is-size-7 has-text-weight-bold has-text-white is-underlined">
              Add all Photos to Cart
            </a>
          </div>
        </div>

        <div v-if="customerCart.length > 0" @click.prevent="emptyCart">
          <div class="ml-4">
            <a class=" is-text is-small is-rounded is-size-7 has-text-weight-semibold has-text-white">
              Empty Cart
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-flex-start is-flex-wrap-wrap	 pb-2">
    <div>
      <div class="is-flex is-flex-direction-row is-justify-content-start is-align-items-center is-size-7 is-unselectable  " >
        <span class="has-tooltip-arrow has-tooltip-right has-tooltip-multiline bottomless" :data-tooltip="toolTipRetouchable">
          <span  class="photo-number-tag  is-flex is-flex-direction-row is-justify-content-start is-align-items-center mr-2" v-if="customerPhotoset?.type === 'retouch'">
            <span class="material-icons-outlined mr-1 is-size-3 has-text-primary has-text-weight-semibold">auto_fix_high</span>
             <span>
               <div class="is-size-5 has-text-centered has-text-weight-bold">{{ customerPhotoset.retoucheablePhotos }}</div>
               <div class="is-size-8 grey-600 has-text-weight-semibold">Retouched</div>
             </span>
          </span>
        </span>
        <span :class="[{'has-tooltip-right': customerPhotoset?.type === 'download' }, 'has-tooltip-arrow  has-tooltip-multiline bottomless']" :data-tooltip="toolTipDownloadable">
          <span  class="photo-number-tag is-flex is-flex-direction-row is-justify-content-start is-align-items-center is-size-7 is-unselectable" v-if="customerPhotoset?.status === 'ready' && downloadables">
            <span class="material-icons-outlined mr-1 is-size-3 has-text-primary has-text-weight-semibold">file_download</span>
             <span>
               <div class="is-size-5 has-text-centered  has-text-weight-bold">{{ downloadableUnretouchedPhotosTotal }}</div>
               <div class="is-size-8 grey-600 has-text-weight-semibold">{{ unretouchedPhotosLabel }}</div>
             </span>
          </span>
        </span>
      </div>
    </div>
    <div class="align-to-bottom">
      <div class="is-size-7 has-text-white has-text-weight-semibold is-flex is-flex-direction-row is-align-items-center">
        <span class="material-icons-outlined is-size-7   has-text-weight-bold icons  is-unselectable"> av_timer </span>
        <span class="ml-1">{{expirationIn}}</span>
      </div>
    </div>
  </div>

</div>
<div>
  <Skeletor height="400" v-if="!customerPhotoset.photos" />
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment';
import { Skeletor } from 'vue-skeletor';
import PhotoCounterMixIn from "@/mixins/PhotoCounterMixIn";
export default {
    name: "PhotosetDetailSection",
    mixins: [PhotoCounterMixIn],
    components: { Skeletor },
    data() {
      return {
        toolTipRetouchable: "Photos you can pick for retouche"
      }
    },
    computed: {
      ...mapGetters([
            'customerCart',
            'customerPhotoset'
        ]),
      // make a mixin out of it 
      expirationIn() {
        return 'expires ' + moment(this.customerPhotoset.expiration, 'YYYY-DD-MMTHH:mm:ssZ').fromNow()
      },
      downloadables() {
          return this.customerPhotoset.downloadAll ? this.customerPhotoset.photoCount : this.customerPhotoset.downloadablePhotos
      },
      unretouchedPhotosLabel() {
        return this.customerPhotoset?.type === 'download' ? 'Photos  ': 'Unretouched'
      },
      toolTipDownloadable() {
        switch(this.customerPhotoset.type) {
          case 'retouch':
            return (this.customerPhotoset.downloadAll) || (this.customerPhotoset.downloadablePhotos >=  this.customerPhotoset.photoCount ) ?  "Photos you can select and download besides retouched photos" : `Photos you can select and download besides retouched photos. If you'd like more, please contact ${this.customerPhotoset.author}`
          case 'download':
            return (this.customerPhotoset.downloadAll) || (this.customerPhotoset.downloadablePhotos >=  this.customerPhotoset.photoCount ) ?  "Photos you can select and download" : `Photos you can select and download. If you'd like more, please contact ${this.customerPhotoset.author}`
          default:
            return (this.customerPhotoset.downloadAll) || (this.customerPhotoset.downloadablePhotos >=  this.customerPhotoset.photoCount ) ?  "Photos you can select and download" : `Photos you can select and download. If you'd like more, please contact ${this.customerPhotoset.author}`
        }
      },
      allowDownloadAll() {
        return (this.customerPhotoset?.downloadAll) || (this.customerPhotoset?.downloadablePhotos >=  this.customerPhotoset?.photoCount ) ? true : false
      },
      backgroundPhoto() {
        // const index = this.customerPhotoset.photos.findLastIndex(e => e.width >= e.height)
        const customerPhotosetLength = this.customerPhotoset.photos.length
        let index = -1;
        for (let i = customerPhotosetLength - 1; i >= 0; i--) {
          if (this.customerPhotoset.photos[i].width >= this.customerPhotoset.photos[i].height) {
            index = i;
            break;
          }
        }
        if (index > -1) {
          return  `background-image: linear-gradient(0deg,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 10%,rgba(0,0,0,0) 70%,rgba(0,0,0,.4) 100%),  url(${this.customerPhotoset.photos[index].src.l['0']}); background-size: cover;  background-position: center; height: 400px;`
        }
          return `linear-gradient(0deg,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 10%,rgba(0,0,0,0) 70%,rgba(0,0,0,.4) 100%), url(${this.customerPhotoset.photos[this.customerPhotoset.photos.length - 1].src.l['0']}); background-size: cover;  background-position: center; height: 400px;`
      },
    
    },
    created() {
      this.$store.dispatch('getAllCartItems', this.customerPhotoset?.id)
    },
    methods: {
      emptyCart() {
        this.$store.dispatch('emptyCart', this.customerPhotoset?.id)
      },
      addAllItemsToCart() {
        this.$store.dispatch('addAllItemsToCart', this.customerPhotoset?.id)
      }
    }

}
</script>

<style>
#photoset-details {
  margin-bottom: -10px;
}
.photo-number-tag {
  border: 1px solid grey;
  padding: 6px;
  background-color: white;
  border-radius: 9px;
  line-height: 1rem;
}
.align-to-bottom {
  align-self: flex-end;
}

.bottomless {
  border-bottom: none !important;
}
</style>